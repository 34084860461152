import { IDinaUppdragContent } from '@local/Views/DinaUppdrag/DinaUppdrag.types'
import { HubConnection } from '@microsoft/signalr'
import { User } from '@trr/app-shell-data'

export interface IDevice {
  isMobile: boolean
  isTablet?: boolean
  isDesktop?: boolean
  isXL?: boolean
}

export interface IAppShellConfiguration {
  application: { isLoggedIn: boolean }
  content: IDinaUppdragContent
  currentKey: string
  url: string
  device: IDevice
  enabledFeatures: string[]
  signalRConnection: ISignalRConnection
  user: User
}

export interface ISignalRConnection {
  klient: HubConnection
}

export enum SignalRRoom {
  UppdragUpdated = 'UppdragUpdated',
  AnsokanUpdated = 'AnsokanUpdated',
  FilansokanUpdated = 'FilansokanUpdated',
}

export enum KlientHub {
  AddToGroup = 'AddToGroup',
  RemoveFromGroup = 'RemoveFromGroup',
}

export interface IAnsokanUpdatedResponseModel {
  AnsokanId: string
}
