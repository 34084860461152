import { JSX } from 'react'
import { Grid2 as Grid, Typography } from '@mui/material'
import { useGetAnsokanQuery } from '@local/services/API/ansokan/skapaAnsokanApi'
import { usePickEpiContent } from '@local/Utils/hooks'
import {
  IUppdragContent,
  IUppdragsAnsokanApiModel,
} from '@local/Views/Uppdrag/Uppdrag.types'
import { isNil } from 'ramda'
import { trrFormat } from '@local/Utils/helpers/formatDate'
import Gutter from '@local/Components/Gutter'
import { formatCurrencySEK, formatPercentageNumber } from '@local/Utils/helpers'
import KeyValuePair from '@local/Views/Uppdrag/AnsokanList/AnsokanCard/KeyValuePair'
import { OrsakerTillUppsagning } from '@local/Common.types'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'

const Ersattning = ({
  row,
}: {
  row: IUppdragsAnsokanApiModel
}): JSX.Element => {
  const {
    ansokanDetaljer: { ersattning },
  } = usePickEpiContent<IUppdragContent>()
  const { data: ansokan, isLoading, isError } = useGetAnsokanQuery(row.id)

  const {
    data: uppdrag,
    isError: isErrorUppdrag,
    isLoading: isLoadingUppdrag,
  } = useGetUppdrag()

  const isTioTbAnsokan =
    uppdrag?.orsakTillUppsagning ===
    OrsakerTillUppsagning.TidsbegransadAnstallning

  const shouldShowSkeleton =
    isLoading || isError || !ansokan || isErrorUppdrag || isLoadingUppdrag

  return (
    <>
      <Typography variant="subtitle2">{ersattning.heading}</Typography>

      <Gutter offset_xs={16} />

      <Grid container spacing={2}>
        <KeyValuePair
          title={ersattning.manadslon}
          value={
            !isNil(ansokan?.loneuppgifter?.manadslon) &&
            formatCurrencySEK(ansokan?.loneuppgifter?.manadslon)
          }
          isLoading={shouldShowSkeleton}
        />

        {!isTioTbAnsokan && (
          <KeyValuePair
            title={ersattning.lonAndradesSenast}
            value={
              !isNil(ansokan?.loneuppgifter?.lonerevisionsdatum) &&
              trrFormat(ansokan?.loneuppgifter?.lonerevisionsdatum)
            }
            isLoading={shouldShowSkeleton}
          />
        )}

        <KeyValuePair
          title={ersattning.bonusProvision}
          value={
            !isNil(ansokan?.loneuppgifter?.provisionBelopp) &&
            formatCurrencySEK(ansokan?.loneuppgifter?.provisionBelopp)
          }
          isLoading={shouldShowSkeleton}
        />

        <KeyValuePair
          title={ersattning.skiftJour}
          value={
            !isNil(ansokan?.loneuppgifter?.jourBelopp) &&
            formatCurrencySEK(ansokan?.loneuppgifter?.jourBelopp)
          }
          isLoading={shouldShowSkeleton}
        />

        {!isTioTbAnsokan && (
          <KeyValuePair
            title={ersattning.avgangsvederlag}
            value={
              !isNil(ansokan?.loneuppgifter?.avgangsvederlag) &&
              formatCurrencySEK(ansokan?.loneuppgifter?.avgangsvederlag)
            }
            isLoading={shouldShowSkeleton}
          />
        )}

        <KeyValuePair
          title={ersattning.sjukersattningsgrad}
          value={
            !isNil(ansokan?.loneuppgifter?.sjukersattningsgrad) &&
            formatPercentageNumber(ansokan?.loneuppgifter?.sjukersattningsgrad)
          }
          isLoading={shouldShowSkeleton}
        />
      </Grid>
    </>
  )
}

export default Ersattning
