import { RootState } from '@local/Store/configureStore'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PersonuppgifterState {
  shouldGetPersonuppgifter: boolean
}

const initialState: PersonuppgifterState = {
  shouldGetPersonuppgifter: false,
}

export const personuppgifterSlice = createSlice({
  name: 'personuppgifter',
  initialState,
  reducers: {
    setShouldGetPersonuppgifter: (state, action: PayloadAction<boolean>) => {
      state.shouldGetPersonuppgifter = action.payload
    },
    resetPersonuppgifterState: () => initialState,
  },
})

export const { setShouldGetPersonuppgifter, resetPersonuppgifterState } =
  personuppgifterSlice.actions

export const selectPersonuppgifterState = (state: RootState) =>
  state.personuppgifter

export default personuppgifterSlice.reducer
