import { JSX } from 'react'
import VarningInvalidAnsokningar from '@local/Views/Uppdrag/AnsokanList/TaBortModal/VarningInvalidAnsokningar'
import TaBortAnsokningar from '@local/Views/Uppdrag/AnsokanList/TaBortModal/TaBortAnsokningar'
import { ITaBortModal } from '@local/Views/Uppdrag/AnsokanList/TaBortModal/TaBortModal.types'
import { Dialog } from '@mui/material'
import { isNil } from 'ramda'

const TaBortModal = ({
  markeradeAnsokningar,
  taBortModalIsOpen,
  apiRef,
  setMarkeradeAnsokningar,
  closeTaBortModal,
}: ITaBortModal): JSX.Element => {
  const hasInvalidAnsokningar = markeradeAnsokningar.some(
    (ansokan) => !isNil(ansokan.inskickadAv)
  )

  return (
    <Dialog
      open={taBortModalIsOpen}
      onClose={closeTaBortModal}
      closeAfterTransition={false}
    >
      {hasInvalidAnsokningar && (
        <VarningInvalidAnsokningar
          markeradeAnsokningar={markeradeAnsokningar}
          setMarkeradeAnsokningar={setMarkeradeAnsokningar}
          closeTabortModal={closeTaBortModal}
          apiRef={apiRef}
        />
      )}

      {!hasInvalidAnsokningar && (
        <TaBortAnsokningar
          setMarkeradeAnsokningar={setMarkeradeAnsokningar}
          markeradeAnsokningar={markeradeAnsokningar}
          closeModal={closeTaBortModal}
          apiRef={apiRef}
        />
      )}
    </Dialog>
  )
}

export default TaBortModal
