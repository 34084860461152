import { JSX } from 'react'
import { Button, Grid2 as Grid, Typography, useTheme } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { useFormikContext } from 'formik'
import {
  ISkapaAnsokningarContent,
  ISkapaAnsokningarFormValues,
} from '@local/Views/SkapaAnsokningar/types/types'
import { useSelector } from 'react-redux'
import {
  setCurrentErrorIndex,
  selectErrorStepper,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/ErrorStepper/errorStepperSlice'
import { useAppDispatch } from '@local/Store/configureStore'
import { IErrorStepper } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/ErrorStepper/types/types'
import {
  getNextErrorIndex,
  getPreviousErrorIndex,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/helpers/common'
import { isEmpty, isNil } from 'ramda'
import NoErrors from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/NoErrors'
import { usePickEpiContent } from '@local/Utils/hooks'

const ErrorStepper = ({ apiRef }: IErrorStepper): JSX.Element => {
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const { currentErrorIndex, errors } = useSelector(selectErrorStepper)
  const {
    sammanfattning: { validering },
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  const { values, errors: formikErrors } =
    useFormikContext<ISkapaAnsokningarFormValues>()

  const stepIntoError = (nextErrorIndex: number) => {
    const nextError = errors[nextErrorIndex]
    const colIndex = apiRef.current.getColumnIndex(nextError.id)
    const rowId = values.filansokningar[errors[nextErrorIndex]?.rowIndex]?.id

    dispatch(setCurrentErrorIndex(nextErrorIndex))

    if (rowId) {
      apiRef.current.scrollToIndexes({ rowIndex: nextError.rowIndex, colIndex })
      apiRef.current.setCellFocus(rowId, nextError.id)
    }
  }

  const handleGoToNextError = () => {
    const nextErrorIndex = getNextErrorIndex(currentErrorIndex, errors)
    stepIntoError(nextErrorIndex)
  }

  const handleGoToPreviousError = () => {
    const previousErrorIndex = getPreviousErrorIndex(currentErrorIndex, errors)

    stepIntoError(previousErrorIndex)
  }

  const currentError =
    values.filansokningar?.[errors?.[currentErrorIndex]?.rowIndex]

  if (isEmpty(errors)) {
    return <NoErrors />
  }

  return (
    <Grid container alignItems="center" gap={1}>
      <Grid>
        <Button
          variant="outlined"
          sx={{
            minWidth: 0,
            padding: theme.spacing(1),
          }}
          onClick={handleGoToPreviousError}
          aria-label="Stega till föregående fel"
        >
          <ChevronLeftIcon />
        </Button>
      </Grid>
      <Grid>
        <Button
          variant="outlined"
          sx={{ minWidth: 0, padding: theme.spacing(1) }}
          onClick={handleGoToNextError}
          aria-label="Stega till nästa fel"
        >
          <ChevronRightIcon />
        </Button>
      </Grid>
      <Grid
        sx={{
          marginLeft: theme.spacing(1),
          display: 'flex',
        }}
      >
        <div>
          <Grid container gap={1}>
            <Grid>
              <Typography variant="body1" color="error">
                {`${String(currentErrorIndex + 1)}/${String(errors.length)}`}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body1" color="error">
                {errors[currentErrorIndex]?.error as string}
              </Typography>
            </Grid>
            <Grid container gap={1}>
              {typeof formikErrors.filansokningar !== 'string' &&
                !isNil(currentError?.namn) &&
                !isEmpty(currentError?.namn) && (
                  <Grid>
                    <Typography variant="body1">
                      ({currentError.namn})
                    </Typography>
                  </Grid>
                )}
              <Grid>
                <Typography variant="body1">-</Typography>
              </Grid>
              <Grid>
                <Typography
                  variant="body1"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {validering.editInfoLabel}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default ErrorStepper
