import { AnsokanKeys, RadioChoice } from '@local/Common.types'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import { isNil } from 'ramda'

export const purifyEntries = (entries: string[]) => ({
  id: entries[0],
  value: entries[1],
})

//Middleware to be used to transform data before storing in redux slice
export const skapaAnsokanStoreMiddleware = (
  changes: Partial<ISkapaAnsokanFormValues>
): Partial<ISkapaAnsokanFormValues> => {
  let changesToStore: Partial<ISkapaAnsokanFormValues> = changes

  Object.entries(changes).forEach((change) => {
    const fieldChange = purifyEntries(change)

    switch (fieldChange.id as AnsokanKeys) {
      case AnsokanKeys.Sjukersattningsgrad:
        if (!isNil(fieldChange.value)) {
          changesToStore = {
            ...changesToStore,
            hasSjukersattningsgrad: RadioChoice.Ja,
          }
        }

        break

      case AnsokanKeys.Avgangsvederlag:
        if (!isNil(fieldChange.value)) {
          changesToStore = {
            ...changesToStore,
            hasAvgangsvederlag: RadioChoice.Ja,
          }
        }

        break
    }
  })

  return changesToStore
}
