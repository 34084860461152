import { useEffect } from 'react'
import { AnsokanState } from '@local/Common.types'
import { GridApiPro } from '@mui/x-data-grid-pro'

const useResetSelectedRowsOnTabChange = ({
  apiRef,
  activeTab,
}: {
  apiRef: React.MutableRefObject<GridApiPro>
  activeTab: AnsokanState
}) => {
  useEffect(() => {
    if (apiRef?.current?.setRowSelectionModel) {
      apiRef.current.setRowSelectionModel([])
    }
  }, [activeTab, apiRef])
}

export default useResetSelectedRowsOnTabChange
