import { JSX } from 'react'
import { styled } from '@mui/material/styles'

const StyledWysiwygEditor = styled('span')(({ theme }) => ({
  whiteSpace: 'pre-line',
  wordWrap: 'break-word',

  '& strong': {
    fontWeight: theme.typography.fontWeightBold,
  },
  '& a': {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    display: 'inline-flex!important',
    alignItems: 'center',
    verticalAlign: 'top',
    '& hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}))

const WysiwygEditor = ({
  htmlFormattedString,
}: {
  htmlFormattedString: string
}): JSX.Element => (
  <StyledWysiwygEditor
    dangerouslySetInnerHTML={{ __html: htmlFormattedString }}
  />
)

export default WysiwygEditor
