import { JSX } from 'react'
import { CircularProgress, styled, CircularProgressProps } from '@mui/material'

const CenteredSpinner = styled('span')(() => ({
  display: 'block',
  textAlign: 'center',
}))

const Spinner = ({
  centered,
  size = 40,
  ...props
}: {
  centered?: boolean
  size?: number
} & CircularProgressProps): JSX.Element =>
  centered ? (
    <CenteredSpinner>
      <CircularProgress size={size} {...props} />
    </CenteredSpinner>
  ) : (
    <CircularProgress size={size} {...props} />
  )

export default Spinner
