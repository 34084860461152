import { JSX } from 'react'
import Gutter from '@local/Components/Gutter'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Grid2 as Grid, useTheme } from '@mui/material'
import FormErrorMessage from '@local/Components/FormErrorMessage/FormErrorMessage'
import { IButtonAreaProps } from '@local/Components/RemovableCard/ButtonArea/types'

const ButtonArea = ({
  handleLaggTill,
  toggleTaBortModal,
  buttonAreaContent,
  cardHasErrors,
}: IButtonAreaProps): JSX.Element => {
  const theme = useTheme()

  return (
    <>
      {cardHasErrors && (
        <>
          <FormErrorMessage>{buttonAreaContent.formError}</FormErrorMessage>
          <Gutter offset_xs={16} />
        </>
      )}
      <Grid container>
        <Grid size={{ xs: 'auto' }} sx={{ marginRight: theme.spacing(2) }}>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={toggleTaBortModal}
          >
            {buttonAreaContent.secondaryButtonText}
          </Button>
        </Grid>
        <Grid size={{ xs: 'auto' }}>
          <Button onClick={handleLaggTill}>
            {buttonAreaContent.primaryButtonText}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ButtonArea
