import { AnsokanKeys } from '@local/Common.types'
import { IAnstallningFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Anstallning/Anstallning.types'
import * as Yup from 'yup'

export const anstallningTioTbValidationSchema = (
  anstallningschema: Yup.ObjectSchema<IAnstallningFormValues>
): Yup.ObjectSchema<IAnstallningFormValues> =>
  anstallningschema.shape({
    [AnsokanKeys.Uppsagningsdatum]: Yup.date().notRequired(),
    [AnsokanKeys.Uppsagningsgrad]: Yup.number().notRequired(),
  })
