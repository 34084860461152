import { JSX, useState, useEffect, useCallback } from 'react'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import { isEmpty } from 'ramda'
import StickyFooter from '@local/Components/StickyMuiFooter/StickyFooter'
import { Button } from '@mui/material'
import { IKompletteraRiskansokanFormValues } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanForm.types'
import { IKompletteraRiskansokanContent } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokan.types'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import { usePickEpiContent } from '@local/Utils/hooks'
import Alert from '@local/Components/Alert'

import SkickaInModal from './SkickaInModal'

const KompletteraRiskansokanFormSubmit = (): JSX.Element => {
  const { errors, validateForm, setTouched } =
    useFormikContext<IKompletteraRiskansokanFormValues>()

  const {
    sidfot,
    nagotGickFel,
    arbetsgivaransokanLinks: { uppdragUrl },
  } = usePickEpiContent<IKompletteraRiskansokanContent>()

  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false)
  const [skickaInModalOpen, setSkickaInModalOpen] = useState(false)

  const { data: ansokan, isError } = useGetAnsokan()
  const redirectUrl = `${uppdragUrl}${ansokan?.uppdragId}/`

  useEffect(() => {
    if (isEmpty(errors)) {
      setShouldShowErrorMessage(false)
    }
  }, [errors])

  const handleSpara = useCallback(() => {
    void (async () => {
      const formErrors = await validateForm()

      if (isEmpty(formErrors)) {
        setSkickaInModalOpen(true)
      } else {
        setTouched(
          setNestedObjectValues<
            FormikTouched<IKompletteraRiskansokanFormValues>
          >(formErrors, true)
        )?.catch((err) => {
          console.log(err)
        })
        setShouldShowErrorMessage(true)
      }
    })()
  }, [validateForm, setTouched])

  const closeSkickaInModal = () => {
    setSkickaInModalOpen(false)
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <>
      <StickyFooter
        leftElements={
          <Button variant="outlined" href={redirectUrl}>
            {sidfot.avbryt}
          </Button>
        }
        rightElements={<Button onClick={handleSpara}>{sidfot.spara}</Button>}
        isError={shouldShowErrorMessage}
        errorMsg={sidfot.felmeddelande}
      />

      <SkickaInModal
        modalOpen={skickaInModalOpen}
        closeModal={closeSkickaInModal}
      />
    </>
  )
}

export default KompletteraRiskansokanFormSubmit
