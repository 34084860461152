export const matchStringByRegex = (input: string, matcher: RegExp): string =>
  matcher.exec(input)?.toString()?.split(',')[0] || ''

export const UUIDRegex = /((\w{4,12}-?)){5}/

export const personnummer = new RegExp(/^(\d{6}|\d{8})[-|(\s)]{0,1}?\d{4}$/)
export const personnummerWithCentury = new RegExp(/^(19|20)\d{6}[-|\s]?\d{4}$/)

export const email = new RegExp(
  /^(?!.*\.\.)(?!^\.)[A-Z0-9._%+-]+(?<!\.)@[A-Z0-9.-]+\.[A-Z]{2,24}$/i
)

export const phone = new RegExp(/^((\+)?)\d+([-+/()]\d+)*$/)
