import { GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid-pro'
import { useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/hooks'
import { AnsokanKeys } from '@local/Common.types'
import {
  ISkapaAnsokningarContent,
  ISkapaAnsokningarFormValues,
} from '@local/Views/SkapaAnsokningar/types/types'
import {
  baseColumnSettings,
  getCommonCellCurrencySettings,
  getCommonCellDecimalSettings,
  getCommonCellPercentageSettings,
  getCommonColumnSettings,
  renderBaseNumberInput,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/helpers/common'
import { IFilAnsokanRowFormik } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/types/types'
import { useMemo } from 'react'

const useGetNumberCells = () => {
  const {
    sammanfattning: { columnHeaders: columnHeadersContent },
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  const formikContext = useFormikContext<ISkapaAnsokningarFormValues>()

  const { values, errors } = formikContext

  const arbetstimmarPerVecka: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.ArbetstimmarPerVecka,
        columnHeadersContent,
        values,
        errors
      ),
      ...getCommonCellDecimalSettings(values, AnsokanKeys.ArbetstimmarPerVecka),
      renderEditCell: (params: GridRenderEditCellParams) =>
        renderBaseNumberInput({
          ansokanKey: AnsokanKeys.ArbetstimmarPerVecka,
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
        }),
    }),
    [columnHeadersContent, errors, values]
  )

  const anstallningsgrad: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.Anstallningsgrad,
        columnHeadersContent,
        values,
        errors
      ),
      ...getCommonCellPercentageSettings(values, AnsokanKeys.Anstallningsgrad),
      renderEditCell: (params) =>
        renderBaseNumberInput({
          ansokanKey: AnsokanKeys.Anstallningsgrad,
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
        }),
    }),
    [columnHeadersContent, errors, values]
  )

  const uppsagningsgrad: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.Uppsagningsgrad,
        columnHeadersContent,
        values,
        errors
      ),
      ...getCommonCellPercentageSettings(values, AnsokanKeys.Uppsagningsgrad),
      renderEditCell: (params) =>
        renderBaseNumberInput({
          ansokanKey: AnsokanKeys.Uppsagningsgrad,
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
        }),
    }),
    [columnHeadersContent, errors, values]
  )

  const manadslon: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.Manadslon,
        columnHeadersContent,
        values,
        errors
      ),
      ...getCommonCellCurrencySettings(values, AnsokanKeys.Manadslon),
      renderEditCell: (params) =>
        renderBaseNumberInput({
          ansokanKey: AnsokanKeys.Manadslon,
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
        }),
    }),
    [columnHeadersContent, errors, values]
  )

  const provisionTantiemBelopp: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.ProvisionTantiemBelopp,
        columnHeadersContent,
        values,
        errors
      ),
      ...getCommonCellCurrencySettings(
        values,
        AnsokanKeys.ProvisionTantiemBelopp
      ),
      renderEditCell: (params) =>
        renderBaseNumberInput({
          ansokanKey: AnsokanKeys.ProvisionTantiemBelopp,
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
        }),
    }),
    [columnHeadersContent, errors, values]
  )

  const skiftJourOBTillaggBelopp: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.SkiftJourOBTillaggBelopp,
        columnHeadersContent,
        values,
        errors
      ),
      ...getCommonCellCurrencySettings(
        values,
        AnsokanKeys.SkiftJourOBTillaggBelopp
      ),
      renderEditCell: (params) =>
        renderBaseNumberInput({
          ansokanKey: AnsokanKeys.SkiftJourOBTillaggBelopp,
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
        }),
    }),
    [columnHeadersContent, errors, values]
  )

  const avgangsvederlag: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.Avgangsvederlag,
        columnHeadersContent,
        values,
        errors
      ),
      ...getCommonCellCurrencySettings(values, AnsokanKeys.Avgangsvederlag),
      renderEditCell: (params) =>
        renderBaseNumberInput({
          ansokanKey: AnsokanKeys.Avgangsvederlag,
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
        }),
    }),
    [columnHeadersContent, errors, values]
  )

  return {
    arbetstimmarPerVecka,
    anstallningsgrad,
    uppsagningsgrad,
    manadslon,
    provisionTantiemBelopp,
    skiftJourOBTillaggBelopp,
    avgangsvederlag,
  }
}

export default useGetNumberCells
