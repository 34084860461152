import React, { JSX, useState } from 'react'
import { isNil } from 'ramda'
import { INumberInput } from '@local/Components/NumberInput/Types'
import { TextField } from '@mui/material'

const NumberInput = ({
  formikContext,
  label,
  name,
  value,
  placeholder,
  error,
  errorMsg,
  onChange,
  handleFocus,
}: INumberInput): JSX.Element => {
  const { touched, errors, values, handleChange, handleBlur } = formikContext
  const isError = error ?? (touched?.[name] && !isNil(errors?.[name]))
  const errorMessage = errorMsg ?? (errors?.[name] as string)
  const [isFocused, setIsFocused] = useState(false)

  const handleScrollwheel = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur()
  }

  const onFocus = () => {
    setIsFocused(true)
  }

  const handleFieldBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleBlur(e)
    setIsFocused(false)
  }
  const shouldShrink =
    (touched?.[name] && !isNil(value)) || isError || !isNil(value) || isFocused

  return (
    <TextField
      fullWidth
      inputProps={{ onWheel: handleScrollwheel }}
      type="number"
      error={isError}
      helperText={isError ? errorMessage : null}
      value={value ?? (values?.[name] as number)}
      label={label}
      placeholder={placeholder}
      name={name}
      onChange={onChange ?? handleChange}
      onFocus={handleFocus ?? onFocus}
      onBlur={handleFieldBlur}
      InputLabelProps={{ shrink: shouldShrink }}
    />
  )
}

export default NumberInput
