import { JSX } from 'react'
import { Grid2 as Grid, Typography } from '@mui/material'
import { useGetAnsokanQuery } from '@local/services/API/ansokan/skapaAnsokanApi'
import { usePickEpiContent } from '@local/Utils/hooks'
import {
  IUppdragContent,
  IUppdragsAnsokanApiModel,
} from '@local/Views/Uppdrag/Uppdrag.types'
import { isNil } from 'ramda'
import { trrFormat } from '@local/Utils/helpers/formatDate'
import Gutter from '@local/Components/Gutter'
import { formatPercentageNumber } from '@local/Utils/helpers'
import KeyValuePair from '@local/Views/Uppdrag/AnsokanList/AnsokanCard/KeyValuePair'
import { OrsakerTillUppsagning } from '@local/Common.types'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'
import { useGetUppdragIsRisk } from '@local/Utils/hooks/useGetUppdragIsRisk'

const OmUppsagningen = ({
  row,
}: {
  row: IUppdragsAnsokanApiModel
}): JSX.Element => {
  const {
    ansokanDetaljer: { omUppsagningen, common },
  } = usePickEpiContent<IUppdragContent>()
  const { data: ansokan, isLoading, isError } = useGetAnsokanQuery(row.id)

  const {
    data: uppdrag,
    isError: isErrorUppdrag,
    isLoading: isLoadingUppdrag,
  } = useGetUppdrag()

  const isTioTbAnsokan =
    uppdrag?.orsakTillUppsagning ===
    OrsakerTillUppsagning.TidsbegransadAnstallning

  const isRisk = useGetUppdragIsRisk()

  const shouldShowSkeleton =
    isLoading || isError || !ansokan || isErrorUppdrag || isLoadingUppdrag

  return (
    <>
      <Typography variant="subtitle2">{omUppsagningen.heading}</Typography>

      <Gutter offset_xs={16} />

      <Grid container spacing={2}>
        {!isTioTbAnsokan && (
          <KeyValuePair
            title={omUppsagningen.uppsagningsdatum}
            value={
              isRisk
                ? common.ejFaststalltLabel
                : !isNil(ansokan?.anstallningsuppgifter?.uppsagningsdatum) &&
                  trrFormat(ansokan?.anstallningsuppgifter?.uppsagningsdatum)
            }
            isLoading={shouldShowSkeleton}
          />
        )}

        {!isTioTbAnsokan && (
          <KeyValuePair
            title={omUppsagningen.uppsagningsgrad}
            value={
              isRisk
                ? common.ejFaststalltLabel
                : !isNil(ansokan?.anstallningsuppgifter?.uppsagningsgrad) &&
                  formatPercentageNumber(
                    ansokan?.anstallningsuppgifter?.uppsagningsgrad
                  )
            }
            isLoading={shouldShowSkeleton}
          />
        )}

        <KeyValuePair
          title={omUppsagningen.sistaAnstallningsdag}
          value={
            isRisk
              ? common.ejFaststalltLabel
              : !isNil(
                  ansokan?.anstallningsuppgifter?.sistaAnstallningsdatum
                ) &&
                trrFormat(
                  ansokan?.anstallningsuppgifter?.sistaAnstallningsdatum
                )
          }
          isLoading={shouldShowSkeleton}
        />
      </Grid>
    </>
  )
}

export default OmUppsagningen
