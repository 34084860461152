import { ISteps } from '@local/Components/Stepper/Stepper.types'
import { RootState } from '@local/Store/configureStore'
import { SkapaAnsokningarStep } from '@local/Views/SkapaAnsokningar/types/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SkapaAnsokningarStepperState {
  steps: ISteps[]
  activeStep: SkapaAnsokningarStep
}

const initialState: SkapaAnsokningarStepperState = {
  steps: [],
  activeStep: null,
}

export const skapaAnsokningarStepperSlice = createSlice({
  name: 'skapaAnsokningarStepper',
  initialState,
  reducers: {
    initStepper: (
      state,
      action: PayloadAction<Partial<SkapaAnsokningarStepperState>>
    ) => {
      state.steps = action.payload.steps
      state.activeStep = action.payload.activeStep
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    goToNext: (state) => {
      state.activeStep =
        (state.activeStep as number) < state.steps.length - 1
          ? state.activeStep + 1
          : state.activeStep
    },
    goToPrevious: (state) => {
      state.activeStep =
        (state.activeStep as number) > 0
          ? state.activeStep - 1
          : state.activeStep
    },
  },
})

export const { setActiveStep, goToNext, goToPrevious, initStepper } =
  skapaAnsokningarStepperSlice.actions

export const selectSkapaAnsokningarStepper = (state: RootState) =>
  state.skapaAnsokningarStepper

export default skapaAnsokningarStepperSlice.reducer
