import { JSX } from 'react'
import { ISkapaAnsokningarContent } from '@local/Views/SkapaAnsokningar/types/types'
import { usePickEpiContent } from '@local/Utils/hooks'
import HTMLMapper from '@local/Components/HTMLMapper'

const SaGorDuEnAnsokan = (): JSX.Element => {
  const {
    laggTillMedarbetare: { saHarGorDuEnAnsokan },
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  return <HTMLMapper body={saHarGorDuEnAnsokan.mainBody} />
}

export default SaGorDuEnAnsokan
