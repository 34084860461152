import { useState } from 'react'

const useSkickaInModal = () => {
  const [skickaInModalIsOpen, setSkickaInModalIsOpen] = useState(false)
  const [shouldResetModalContent, setShouldResetModalContent] = useState(false)

  const openSkickaInModal = () => {
    setShouldResetModalContent(true)
    setSkickaInModalIsOpen(true)
  }

  const closeSkickaInModal = () => {
    setSkickaInModalIsOpen(false)
    setShouldResetModalContent(false)
  }

  return {
    skickaInModalIsOpen,
    setSkickaInModalIsOpen,
    shouldResetModalContent,
    setShouldResetModalContent,
    openSkickaInModal,
    closeSkickaInModal,
  }
}

export default useSkickaInModal
