import { MobileStepper } from '@mui/material'
import { styled } from '@mui/material/styles'
import { IStepperProgress } from '@local/Components/StepperProgress/types/types'

const StyledStepperProgress = styled(MobileStepper)(() => ({
  padding: 0,

  '& .MuiMobileStepper-progress': {
    width: '100%',
  },
}))

const StepperProgress = ({ steps, activeStep }: IStepperProgress) => (
  <StyledStepperProgress
    variant="progress"
    steps={steps.length}
    position="static"
    activeStep={activeStep}
    nextButton={null}
    backButton={null}
  />
)

export default StepperProgress
