import { RootState } from '@local/Store/configureStore'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IRemovableCardState {
  shouldExpandCard: boolean
  shouldScrollToCard: boolean
}

const initialState: IRemovableCardState = {
  shouldExpandCard: false,
  shouldScrollToCard: false,
}

export const removableCardSlice = createSlice({
  name: 'removableCard',
  initialState,
  reducers: {
    setShouldExpandCard: (state, action: PayloadAction<boolean>) => {
      state.shouldExpandCard = action.payload
    },
    setShouldScrollToCard: (state, action: PayloadAction<boolean>) => {
      state.shouldScrollToCard = action.payload
    },
    setShouldHighlightCard: (state, action: PayloadAction<boolean>) => {
      state.shouldExpandCard = action.payload
      state.shouldScrollToCard = action.payload
    },
  },
})

export const {
  setShouldExpandCard,
  setShouldScrollToCard,
  setShouldHighlightCard,
} = removableCardSlice.actions

export const selectRemovableCard = (state: RootState) => state.removableCard

export default removableCardSlice.reducer
