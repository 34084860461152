import { useEffect, useState } from 'react'
import { Form, withFormik, FormikProps } from 'formik'
import {
  ISkapaAnsokanFormProps,
  ISkapaAnsokanFormValues,
} from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import { skapaAnsokanValidationSchema } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.schema'
import { useDirtyCheckPrompt } from '@trr/react-use-dirty-check-prompt'
import { Grid2 as Grid } from '@mui/material'
import { mapAnsokanToMedarbetareFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Medarbetare/helpers'
import { mapAnsokanToAnstallningFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Anstallning/helpers'
import { mapAnsokanToLonFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Lon/helpers'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import SkapaAnsokanTracker from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanTracker'
import Toaster from '@local/Components/Toaster'
import { useFormikWatch } from '@local/Utils/hooks'
import { skapaAnsokanWatcherMiddleware } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/skapaAnsokanWatcherMiddleware'
import { usePatchAnsokan, useReduxStore } from '@local/Views/SkapaAnsokan/hooks'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import Gutter from '@local/Components/Gutter'
import { AnsokanState, Status } from '@local/Common.types'
import Alert from '@local/Components/Alert'

import SkapaAnsokanFormSubmit from './SkapaAnsokanFormSubmit/SkapaAnsokanFormSubmit'
import SkapaAnsokanStepper from './SkapaAnsokanStepper'

const SkapaAnsokanForm = ({
  content,
  values,
  initialValues,
}: ISkapaAnsokanFormProps & FormikProps<ISkapaAnsokanFormValues>) => {
  const shouldTrack = useIsFeatureEnabled('gtm.enabled.kundansokan')

  const { data: ansokan, isError: isErrorGetAnsokan } = useGetAnsokan()
  const { setStoredValuesAfterPatch, storedValues, formIsDirty } =
    useReduxStore(initialValues, values)
  const [toasterOpen, setToasterOpen] = useState(false)
  const { stepper, content: stepperContent } = SkapaAnsokanStepper()

  const { routerPrompt } = useDirtyCheckPrompt(
    formIsDirty,
    content.varningLamnaSidan.description
  )

  const { isError: isErrorPatchAnsokan, patchAnsokan } = usePatchAnsokan(
    setStoredValuesAfterPatch,
    storedValues
  )

  useFormikWatch(skapaAnsokanWatcherMiddleware, patchAnsokan)

  useEffect(() => {
    if (isErrorPatchAnsokan) {
      setToasterOpen(true)
    } else {
      setToasterOpen(false)
    }
  }, [isErrorPatchAnsokan])

  if (isErrorGetAnsokan) {
    return <Alert severity="error">{content.nagotGickFel.heading}</Alert>
  }

  return (
    <Grid container>
      <Toaster
        message={content.patchGickFel.heading}
        open={toasterOpen}
        setOpen={setToasterOpen}
      />
      {routerPrompt}
      {shouldTrack && <SkapaAnsokanTracker />}
      <Grid size={{ xs: 12, md: 4 }}>{stepper}</Grid>
      <Grid size={{ xs: 12, md: 8 }}>
        {(ansokan.ansokanState === AnsokanState.Inskickad ||
          ansokan.status === Status.RedigerasAvArbetsgivare) && (
          <Grid container>
            <Grid size={{ xs: 'grow', md: 8 }}>
              <Alert severity="info">
                {content.varningInskickadAnsokan.heading}
              </Alert>
              <Gutter offset_xs={32} />
            </Grid>
          </Grid>
        )}
        <Form aria-label="Skapa ansökan">
          {stepperContent}
          <SkapaAnsokanFormSubmit />
        </Form>
      </Grid>
    </Grid>
  )
}

const SkapaAnsokanFormik = withFormik<
  ISkapaAnsokanFormProps,
  ISkapaAnsokanFormValues
>({
  validateOnBlur: true,
  mapPropsToValues: ({ ansokan, bilagor }) => ({
    ...mapAnsokanToMedarbetareFormValues(ansokan, bilagor),
    ...mapAnsokanToAnstallningFormValues(ansokan),
    ...mapAnsokanToLonFormValues(ansokan),
  }),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({ content, ansokan }: ISkapaAnsokanFormProps) =>
    skapaAnsokanValidationSchema(content, ansokan),
  displayName: 'SkapaAnsokanForm',
})(SkapaAnsokanForm)

export default SkapaAnsokanFormik
