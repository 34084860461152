import { useEffect, useRef } from 'react'
import { diff } from 'deep-object-diff'
import { FormikErrors, useFormikContext } from 'formik'
import { useAppDispatch } from '@local/Store/configureStore'
import { FormikWatcherValues } from '@local/Common.types'
import { setWatcherIsRunning } from '@local/Utils/helpers/formikWatchSlice'

export interface IMiddlewareField<T extends Partial<FormikWatcherValues>> {
  id: keyof T
  value: unknown
}

interface IMiddlewareValues {
  [x: string]: unknown
}

interface IRefs<T> {
  prevValues: T
  prevErrors: FormikErrors<T>
}

type IValuesBeforeMiddleware = IMiddlewareValues

// Default middleware to be used if no transformation was necessary in middleware
// Will simply return formik id and value without any transformations
export const defaultMiddleware = <T extends Partial<FormikWatcherValues>>(
  field: IMiddlewareField<T>
): IMiddlewareValues => ({
  [field.id]: field.value,
})

//Purifier for readability
export const purifyFieldChange = <T extends Partial<FormikWatcherValues>>(
  entries: string[]
): IMiddlewareField<T> => ({
  id: entries[0] as keyof T,
  value: entries[1],
})

const useFormikWatch = <T extends Partial<FormikWatcherValues>>(
  /**
   * Hook which triggers whenever a field has been changed in a formik state
   * This field change will then be validated by checking for errors in formik
   * If no errors was found, the field change will then pass through a middleware to execute necessary transformations (if any)
   * Finally, it will be sent back to a callback function which is declared outside of this hook. Perhaps in the form of a PATCH-request
   *
   * @param middleware Can be used to transform values and/or formik identifiers before sending a PATCH operation for example
   * @param callback Callback to be triggered when watcher and middleware has been processed (a PATCH for example)
   * @param customFormikValues //Optional, only necessary if formik has been setup with nested steps
   * @param customFormikErrors //Optional, only necessary if formik has been setup with nested steps
   */
  middleware: (
    field: IMiddlewareField<T>, // Field that has been changed
    _defaultMiddleware: (field: IMiddlewareField<T>) => IMiddlewareValues
  ) => IMiddlewareValues = defaultMiddleware,
  callback: (
    result: IMiddlewareValues, //Result after field change has been processed in middleware
    resultBeforeMiddleware: IMiddlewareValues //Result before field change has been processed in middleware
  ) => void,
  customFormikValues?: T,
  customFormikErrors?: FormikErrors<T>
) => {
  const dispatch = useAppDispatch()
  const { values: formikValues, errors: formikErrors } = useFormikContext<T>()
  const values = customFormikValues ?? formikValues
  const errors = customFormikErrors ?? formikErrors
  const formikRefs = useRef<IRefs<T>>({ prevValues: null, prevErrors: null })
  const timer = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    // Track state for when watcher is initiated
    dispatch(setWatcherIsRunning(true))

    // Start by checking if we have a previous formik state to work with
    // Will only come back as undefined when page loads the first time
    // In that case it will go to the "else"-clause and set formiks current state as previous state
    // Later on, when a field has been changed, we can then compare the previous formik state with the current formik state to determine which field has been changed
    if (formikRefs.current.prevValues) {
      //Make sure to clear previous timer, if such exists
      if (timer.current) {
        clearTimeout(timer.current)
      }

      // Set new timer, in this case the watcher will wait 500ms before executing
      // Works as a debounce when user is typing
      timer.current = setTimeout(() => {
        // Declare object where values will be stored after they have been processed in middleware (to be used in a PATCH for example)
        let callbackArgs: IMiddlewareValues = {}

        // Declare object where values will be stored before passing through the middleware (can be used to set a redux store if necessary)
        let callbackArgsBeforeMiddleware: IValuesBeforeMiddleware = {}

        // Retrieve all changes in values that has occured the last 500ms by comparing the previous formik state with the current formik state
        const changes = Object.entries(
          diff(formikRefs.current.prevValues, values)
        )

        // Iterate changes in values
        changes.forEach((change) => {
          // Simple purifier for better readability
          // Converts "entries[0]" to "id" for example
          const fieldChange = purifyFieldChange(change)

          // Make sure that no errors exists in formik after field was changed
          if (!errors[fieldChange.id]) {
            // Store field change before passing it to the middleware
            callbackArgsBeforeMiddleware = {
              ...callbackArgsBeforeMiddleware,
              [fieldChange.id]: fieldChange.value,
            }

            // Pass changed value to the middleware and execute necessary transformations
            const callbackValue = middleware(fieldChange, defaultMiddleware)

            // Necessary check since middleware might deny value to pe passed through callback (bilagor for example since they are patched separately)
            if (callbackValue) {
              // Store changed values after they have been processed in middleware
              callbackArgs = {
                ...callbackArgs,
                ...callbackValue,
              }
            }
          }
        })

        // Retrieve all changes in errors that has occured the last 500ms by comparing the previous formik state with the current formik state
        const changesInErrors = Object.entries(
          diff(formikRefs.current.prevErrors, errors)
        )

        // Iterate changes in errors
        changesInErrors.forEach((change) => {
          // Simple purifier for better readability
          const errorChange = purifyFieldChange(change)

          // If some error no longer exists and that same error is not connected to any of the changed values in the last 500ms,
          // then the value connected to the resolved error should be returned in callback as well
          if (
            !errorChange.value &&
            changes.some((changedValue) => changedValue[0] !== errorChange.id)
          ) {
            // Retrieve value where error was resolved
            const valueWithResolvedError = values[errorChange.id] as unknown

            // Store value before passing it to the middleware
            callbackArgsBeforeMiddleware = {
              ...callbackArgsBeforeMiddleware,
              [errorChange.id]: valueWithResolvedError,
            }

            // Pass value where error was resolved to the middleware and execute necessary transformations
            const callbackValue = middleware(
              { id: errorChange.id, value: valueWithResolvedError },
              defaultMiddleware
            )

            // Necessary check since middleware might deny value to pe passed through callback
            if (callbackValue) {
              // Store value after it has been processed in middleware
              callbackArgs = {
                ...callbackArgs,
                ...callbackValue,
              }
            }
          }
        })

        // Only execute callback if callbackArgs contains processed field changes
        if (Object.keys(callbackArgs).length > 0) {
          //Execute callback (PATCH for example)
          callback(callbackArgs, callbackArgsBeforeMiddleware)

          // Track state for when watcher has finished
          dispatch(setWatcherIsRunning(false))
        }

        // Track state for when watcher has finished
        // In this case, no action was necessary since callbackArgs is empty
        else {
          dispatch(setWatcherIsRunning(false))
        }

        // Always reinitiate state after watcher has finished in order to have correct state next time watcher initiates
        formikRefs.current.prevValues = values
        formikRefs.current.prevErrors = errors
      }, 500)
    }

    // If no previous state existed --> Set current state as previous state to prepare watcher for the next time it initiates
    // Only applicable when page loads the first time
    else {
      formikRefs.current.prevValues = values
      formikRefs.current.prevErrors = errors
      dispatch(setWatcherIsRunning(false))
    }
  }, [errors, values, callback, middleware, dispatch])
}

export default useFormikWatch
