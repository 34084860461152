import { useGetDinaKompletteraRiskUppdragQuery } from '@local/services/API/kompletteraRisk/kompletteraRiskApi'
import { useAuthentication, useUser } from '@trr/app-shell-data'
import { isEmpty, isNil } from 'ramda'

const useGetDinaKompletteraRiskUppdrag = () => {
  const { kundProperties } = useUser()
  const { sub } = useAuthentication()

  return useGetDinaKompletteraRiskUppdragQuery(
    {
      userSub: sub,
      organisationsnummer: kundProperties?.foretag?.organizationNumber,
    },
    { skip: isNil(kundProperties) || isEmpty(kundProperties) }
  )
}

export default useGetDinaKompletteraRiskUppdrag
