import { JSX, useCallback, useEffect, useMemo } from 'react'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import Medarbetare from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Medarbetare'
import { usePickEpiContent } from '@local/Utils/hooks'
import Stepper from '@local/Components/Stepper'
import Anstallning from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Anstallning'
import { ISteps } from '@local/Components/Stepper/Stepper.types'
import { useSelector } from 'react-redux'
import {
  ISkapaAnsokanFormValues,
  SkapaAnsokanStep,
  SkapaAnsokanStepFormikId,
} from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import Lon from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Lon'
import { FormikContextType, useFormikContext } from 'formik'
import {
  initStepper,
  selectStepper,
  setActiveStep,
} from '@local/Views/SkapaAnsokan/SkapaAnsokanNavigation/skapaAnsokanNavigationSlice'
import { useAppDispatch } from '@local/Store/configureStore'
import stepsContext from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/stepsContext'
import { IKeyValue } from '@local/Common.types'
import { isFieldErrorAndTouched } from '@local/Utils/helpers/Forms/Forms.helpers'

const getStepHasErrors = (
  step: SkapaAnsokanStepFormikId,
  formikContext: FormikContextType<IKeyValue>
) => {
  let stepHasErrors = false
  const { touched, errors } = formikContext

  stepsContext[step].forEach((fieldInStep) => {
    if (isFieldErrorAndTouched(errors, touched, fieldInStep)) {
      stepHasErrors = true
    }
  })

  return stepHasErrors
}

const SkapaAnsokanStepper = (): {
  stepper: JSX.Element
  content: JSX.Element
} => {
  const dispatch = useAppDispatch()
  const formikContext = useFormikContext<ISkapaAnsokanFormValues>()
  const { tabbar } = usePickEpiContent<ISkapaAnsokanContent>()
  const { activeStep } = useSelector(selectStepper)

  const steps = useMemo(() => {
    const tempSteps: ISteps[] = [
      {
        step: SkapaAnsokanStep.Medarbetare,
        title: tabbar.medarbetare,
        formikId: SkapaAnsokanStepFormikId.Medarbetare,
        stepHasErrors: getStepHasErrors(
          SkapaAnsokanStepFormikId.Medarbetare,
          formikContext
        ),
      },
      {
        step: SkapaAnsokanStep.Anstallning,
        title: tabbar.anstallning,
        formikId: SkapaAnsokanStepFormikId.Anstallning,
        stepHasErrors: getStepHasErrors(
          SkapaAnsokanStepFormikId.Anstallning,
          formikContext
        ),
      },
      {
        step: SkapaAnsokanStep.Lon,
        title: tabbar.lon,
        formikId: SkapaAnsokanStepFormikId.Lon,
        stepHasErrors: getStepHasErrors(
          SkapaAnsokanStepFormikId.Lon,
          formikContext
        ),
      },
    ]

    return tempSteps
  }, [tabbar.anstallning, tabbar.lon, tabbar.medarbetare, formikContext])

  useEffect(() => {
    dispatch(initStepper({ steps, activeStep: SkapaAnsokanStep.Medarbetare }))
    // Only setup once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setActiveStepCallback = useCallback(
    (step: number) => {
      dispatch(setActiveStep(step))
      window.scroll({ top: 0 })
    },
    [dispatch]
  )

  const tabContentByStep: { [step: number]: JSX.Element } = {
    [SkapaAnsokanStep.Medarbetare]: <Medarbetare />,
    [SkapaAnsokanStep.Anstallning]: <Anstallning />,
    [SkapaAnsokanStep.Lon]: <Lon />,
  }

  const stepper = (
    <Stepper
      steps={steps}
      activeStep={activeStep ?? 0}
      setActiveStep={setActiveStepCallback}
    />
  )

  const content = <>{tabContentByStep[activeStep]}</>
  return { stepper, content }
}

export default SkapaAnsokanStepper
