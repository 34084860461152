import { JSX } from 'react'
import {
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material'
import { usePickEpiContent } from '@local/Utils/hooks'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { IUppdragContent } from '@local/Views/Uppdrag/Uppdrag.types'
import { replaceEpiVariables } from '@local/Utils/helpers'
import { ArbetsgivaransokanStatus } from '@local/Common.types'

import { IAnsokningarInvalid } from './AnsokningarInvalid.types'

const AnsokningarInvalid = ({
  markeradeAnsokningar,
  apiRef,
  setMarkeradeAnsokningar,
  closeSkickaInModal,
}: IAnsokningarInvalid): JSX.Element => {
  const { skickaInVarningModal } = usePickEpiContent<IUppdragContent>()

  const shouldTrack = useIsFeatureEnabled('gtm.enabled.kundansokan')
  const trackButtonClick = useTrackCustomClick()

  const invalidAnsokningar = markeradeAnsokningar.filter(
    (ansokan) =>
      ansokan.arbetsgivaransokanStatus === ArbetsgivaransokanStatus.EjKomplett
  )

  const validAnsokningar = markeradeAnsokningar.filter(
    (ansokan) =>
      ansokan.arbetsgivaransokanStatus ===
        ArbetsgivaransokanStatus.EjInskickad ||
      ansokan.arbetsgivaransokanStatus === ArbetsgivaransokanStatus.Redigerad
  )

  const hasValidAnsokningar = validAnsokningar.length > 0

  const handleOnConfirm = () => {
    if (shouldTrack) {
      trackButtonClick('Modal: Ansökningar ej kompletta', {
        label: 'Gå vidare med kompletta ansökningar',
      })
    }

    setMarkeradeAnsokningar(validAnsokningar)
    apiRef.current.setRowSelectionModel(
      validAnsokningar.map((ansokan) => ansokan.id)
    )
  }

  return (
    <>
      <DialogTitle>{skickaInVarningModal.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            {hasValidAnsokningar &&
              replaceEpiVariables(skickaInVarningModal.description, {
                incompleteAnsokningarCount: String(invalidAnsokningar.length),
              })}

            {!hasValidAnsokningar &&
              skickaInVarningModal.descriptionNoValidAnsokningar}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeSkickaInModal}>
          {skickaInVarningModal.avbryt}
        </Button>
        {hasValidAnsokningar && (
          <Button variant="text" onClick={handleOnConfirm}>
            {skickaInVarningModal.confirmText}
          </Button>
        )}
      </DialogActions>
    </>
  )
}

export default AnsokningarInvalid
