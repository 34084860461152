import React, { JSX } from 'react'
import { TextField } from '@mui/material'
import { isNil } from 'ramda'
import { ITextInput } from '@local/Components/TextInput/Types'
import { handleChangeWithTrim } from '@local/Utils/helpers/Forms/Forms.helpers'

const TextInput = ({
  withTrim = false,
  formikContext,
  value,
  label,
  name,
  placeholder,
  inputMode,
  maxLength,
  error,
  errorMsg,
  onChange,
  handleFocus,
}: ITextInput): JSX.Element => {
  const { errors, touched, values, handleChange, handleBlur } = formikContext
  const isError = error ?? (touched?.[name] && !isNil(errors?.[name]))
  const errorMessage = errorMsg ?? (errors?.[name] as string)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (withTrim) {
      handleChangeWithTrim(e, handleChange)
    } else {
      handleChange(e)
    }
  }

  return (
    <TextField
      placeholder={placeholder}
      fullWidth
      inputProps={{
        inputMode: inputMode,
        autoComplete: inputMode === 'email' ? 'email' : null,
        maxLength,
      }}
      error={isError}
      helperText={isError ? errorMessage : null}
      value={value ?? (values?.[name] as string)}
      label={label}
      onChange={onChange ?? handleInputChange}
      name={name}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  )
}

export default TextInput
