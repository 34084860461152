import { RootState } from '@local/Store/configureStore'
import {
  getNextErrorIndex,
  getPreviousErrorIndex,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/helpers/common'
import { IFilansokanError } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/types/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IErrorStepperState {
  errors: IFilansokanError[]
  currentErrorIndex: number
}

const initialState: IErrorStepperState = {
  errors: [],
  currentErrorIndex: 0,
}

export const errorStepperSlice = createSlice({
  name: 'errorStepper',
  initialState,
  reducers: {
    setErrors: (state, action: PayloadAction<IFilansokanError[]>) => {
      state.errors = action.payload
    },
    setCurrentErrorIndex: (state, action: PayloadAction<number>) => {
      state.currentErrorIndex = action.payload
    },
    goToNextError: (state) => {
      state.currentErrorIndex = getNextErrorIndex(
        state.currentErrorIndex,
        state.errors
      )
    },
    goToPreviousError: (state) => {
      state.currentErrorIndex = getPreviousErrorIndex(
        state.currentErrorIndex,
        state.errors
      )
    },
  },
})

export const {
  setErrors,
  setCurrentErrorIndex,
  goToNextError,
  goToPreviousError,
} = errorStepperSlice.actions

export const selectErrorStepper = (state: RootState) => state.errorStepper

export default errorStepperSlice.reducer
