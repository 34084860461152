import { useMemo } from 'react'
import {
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-pro'
import { useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/hooks'
import { AnsokanKeys, ISelectOption, RadioChoice } from '@local/Common.types'
import {
  ISkapaAnsokningarContent,
  ISkapaAnsokningarFormValues,
} from '@local/Views/SkapaAnsokningar/types/types'
import {
  baseColumnSettings,
  getCommonCellRadioChoiceSettings,
  getCommonColumnSettings,
  renderBaseDropdownInput,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/helpers/common'
import { formatPercentageNumber } from '@local/Utils/helpers'
import { IFilAnsokanRowFormik } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/types/types'
import { isNil } from 'ramda'
import { Typography } from '@mui/material'

const useGetDropdownCells = () => {
  const {
    sammanfattning: { columnHeaders: columnHeadersContent },
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  const formikContext = useFormikContext<ISkapaAnsokningarFormValues>()

  const { values, errors } = formikContext

  const hasTjanstemannaavtal: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.HasTjanstemannaavtal,
        columnHeadersContent,
        values,
        errors
      ),
      ...getCommonCellRadioChoiceSettings(
        values,
        AnsokanKeys.HasTjanstemannaavtal
      ),
      renderEditCell: (params: GridRenderEditCellParams) =>
        renderBaseDropdownInput({
          ansokanKey: AnsokanKeys.HasTjanstemannaavtal,
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
          selectOptions: [
            { label: 'Ja', value: RadioChoice.Ja },
            { label: 'Nej', value: RadioChoice.Nej },
          ],
        }),
    }),
    [columnHeadersContent, errors, values]
  )

  const isVdEllerUndantagsgrupp: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.IsVdEllerUndantagsgrupp,
        columnHeadersContent,
        values,
        errors
      ),
      ...getCommonCellRadioChoiceSettings(
        values,
        AnsokanKeys.IsVdEllerUndantagsgrupp
      ),
      renderEditCell: (params: GridRenderEditCellParams) =>
        renderBaseDropdownInput({
          ansokanKey: AnsokanKeys.IsVdEllerUndantagsgrupp,
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
          selectOptions: [
            { label: 'Ja', value: RadioChoice.Ja },
            { label: 'Nej', value: RadioChoice.Nej },
          ],
        }),
    }),
    [columnHeadersContent, errors, values]
  )

  const sjukersattningsgrad: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.Sjukersattningsgrad,
        columnHeadersContent,
        values,
        errors
      ),
      type: 'singleSelect',
      sortComparator: (option1: ISelectOption, option2: ISelectOption) =>
        Number(option1.value) - Number(option2.value),
      renderCell: (params: GridRenderCellParams) => {
        const currentRow: IFilAnsokanRowFormik =
          params.row as IFilAnsokanRowFormik

        const currentOption =
          values.filansokningar[currentRow.index]?.[
            AnsokanKeys.Sjukersattningsgrad
          ]

        if (!isNil(currentOption)) {
          return (
            <Typography variant="body1">
              {formatPercentageNumber(currentOption.value as number)}
            </Typography>
          )
        }
      },
      renderEditCell: (params: GridRenderEditCellParams) =>
        renderBaseDropdownInput({
          ansokanKey: AnsokanKeys.Sjukersattningsgrad,
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
          selectOptions: [
            { label: formatPercentageNumber(0), value: 0 },
            { label: formatPercentageNumber(25), value: 25 },
            { label: formatPercentageNumber(50), value: 50 },
            { label: formatPercentageNumber(75), value: 75 },
            { label: formatPercentageNumber(100), value: 100 },
          ],
        }),
    }),
    [columnHeadersContent, errors, values]
  )

  return { hasTjanstemannaavtal, isVdEllerUndantagsgrupp, sjukersattningsgrad }
}

export default useGetDropdownCells
