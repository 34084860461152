import { ISteps } from '@local/Components/Stepper/Stepper.types'
import { RootState } from '@local/Store/configureStore'
import { SkapaAnsokanStep } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface NavigationState {
  steps: ISteps[]
  activeStep: SkapaAnsokanStep
}

const initialState: NavigationState = {
  steps: [],
  activeStep: null,
}

export const stepperSlice = createSlice({
  name: 'skapaAnsokanNavigation',
  initialState,
  reducers: {
    initStepper: (state, action: PayloadAction<NavigationState>) => {
      state.steps = action.payload.steps
      state.activeStep = action.payload.activeStep
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    gotoNext: (state) => {
      state.activeStep =
        (state.activeStep as number) < state.steps.length - 1
          ? state.activeStep + 1
          : state.activeStep
    },
    gotoPrevious: (state) => {
      state.activeStep =
        (state.activeStep as number) > 0
          ? state.activeStep - 1
          : state.activeStep
    },
  },
})

export const { setActiveStep, gotoNext, gotoPrevious, initStepper } =
  stepperSlice.actions

export const selectStepper = (state: RootState) => state.skapaAnsokanNavigation

export default stepperSlice.reducer
