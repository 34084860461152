import { JSX } from 'react'
import {
  useIsMediumScreensOrAbove,
  usePickEpiContent,
} from '@local/Utils/hooks'
import {
  IDinaUppdragContent,
  IUppdragProps,
} from '@local/Views/DinaUppdrag/DinaUppdrag.types'
import { appendComma } from '@local/Utils/helpers'
import CompressedList from '@local/Components/CompressedList'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Grid2 as Grid, Typography } from '@mui/material'
import AnsokanCard from '@local/Components/AnsokanCard'

import FackligaKontakterItem from './FackligaKontakterItem'

const Uppdrag = ({ uppdrag }: IUppdragProps): JSX.Element => {
  const {
    uppdrag: uppdragContent,
    orsakTillUppsagning,
    arbetsgivaransokanLinks: { uppdragUrl },
  } = usePickEpiContent<IDinaUppdragContent>()

  const isMediumScreensOrAbove = useIsMediumScreensOrAbove()

  const getFormattedArbetsstalle = () =>
    appendComma(uppdrag.arbetsstalle.ort) +
    appendComma(uppdrag.arbetsstalle.adress) +
    uppdrag.arbetsstalle.namn

  const getMedarbetareLabel = () =>
    uppdrag.antalMedarbetare === 0
      ? uppdragContent.antalMedarbetareSaknas
      : `${String(uppdrag.antalMedarbetare)} ${uppdragContent.antalMedarbetareSuffix}`

  const UppdragColumnItem = ({
    header,
    body,
  }: {
    header?: string
    body?: string | JSX.Element
  }): JSX.Element => (
    <Grid size={{ xs: 12, sm: 6, md: 4 }}>
      <Typography variant="subtitle1">{header}</Typography>
      <Typography variant="body2">{body}</Typography>
    </Grid>
  )
  return (
    <AnsokanCard
      title={uppdrag.namn}
      subtitle={
        <Grid container>
          <Grid mr={1}>
            <Typography variant="subtitle1">
              {uppdragContent.uppdragsnummerLabel}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="subtitle1">{uppdrag.nummer}</Typography>
          </Grid>
        </Grid>
      }
      actionAlign="center"
      action={
        isMediumScreensOrAbove && (
          <Grid container alignItems="center">
            <Typography variant="h6" color="primary" mr={1}>
              {uppdragContent.linkLabel}
            </Typography>
            <ArrowForwardIosIcon color="primary" />
          </Grid>
        )
      }
      href={`${uppdragUrl}${uppdrag.id}/`}
      buttonLinkText={uppdragContent.linkLabel}
    >
      <Grid px={1} container rowGap={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <UppdragColumnItem
          header={uppdragContent.orsakTillUppsagningLabel}
          body={orsakTillUppsagning[uppdrag.orsakTillUppsagning]}
        />
        <UppdragColumnItem
          header={uppdragContent.arbetsplatsLabel}
          body={getFormattedArbetsstalle()}
        />
        <UppdragColumnItem
          header={uppdragContent.administratorerLabel}
          body={
            <CompressedList
              list={uppdrag.administratorer.map((x) => x.namn)}
              maxNumberOfItems={2}
            />
          }
        />
        <UppdragColumnItem
          header={uppdragContent.antalMedarbetareLabel}
          body={getMedarbetareLabel()}
        />
        <FackligaKontakterItem
          label={uppdragContent.fackligaKontakterLabel}
          uppdrag={uppdrag}
        />
      </Grid>
    </AnsokanCard>
  )
}

export default Uppdrag
