import { baseApi } from '@local/services/API/baseApi'
import {
  IDeleteOverenskommelseBilagaApiModel,
  IOverenskommelseBilagaApiModel,
  IUniqueFile,
  IUploadOverenskommelseBilagaApiModel,
} from '@local/services/API/bilagor/types'

export const overenskommelseBilagaApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: (builder) => ({
    getAllOverenskommelseBilaga: builder.query<IUniqueFile[], string>({
      query: (ansokanId) =>
        `ansokan/bilagor/overenskommelse/ansokan/${ansokanId}`,
      providesTags: ['overenskommelsebilaga'],
      transformResponse: (
        baseQueryReturnValue: IOverenskommelseBilagaApiModel[]
      ) =>
        baseQueryReturnValue.map((bilaga) => ({
          id: bilaga.fileId,
          name: bilaga.name,
        })),
    }),

    uploadOverenskommelseBilaga: builder.mutation<
      Response,
      IUploadOverenskommelseBilagaApiModel
    >({
      query: ({ formData, ansokanId }) => ({
        url: `ansokan/bilagor/overenskommelse/ansokan/${ansokanId}/bilaga`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: [
        'overenskommelsebilaga',
        { type: 'ansokan', id: 'GROUPED_LIST' },
        { type: 'ansokan', id: 'LIST' },
      ],
    }),
    deleteOverenskommelseBilaga: builder.mutation<
      Response,
      IDeleteOverenskommelseBilagaApiModel
    >({
      query: ({ ansokanId, bilagaId }) => ({
        url: `ansokan/bilagor/overenskommelse/ansokan/${ansokanId}/bilaga/${bilagaId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'overenskommelsebilaga',
        { type: 'ansokan', id: 'GROUPED_LIST' },
        { type: 'ansokan', id: 'LIST' },
      ],
    }),
  }),
})

export const {
  useGetAllOverenskommelseBilagaQuery,
  useUploadOverenskommelseBilagaMutation,
  useDeleteOverenskommelseBilagaMutation,
} = overenskommelseBilagaApi
