import { FackligKontaktSaknasOrsakerTillUppsagning } from '@local/Common.types'
import { useIsBegransadRedigering } from '@local/Views/UppdragFormWrapper/UppdragForm/hooks'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import { useFormikContext } from 'formik'

const useShouldShowFackligKontaktSaknas = () => {
  const isBegransadRedigering = useIsBegransadRedigering()
  const { values } = useFormikContext<IUppdragFormValues>()

  const shouldShowFackligKontaktSaknasForSkapaUppdrag =
    values.fackligaKontakter?.length <= 0 &&
    Object.values<string>(FackligKontaktSaknasOrsakerTillUppsagning).includes(
      values.orsakTillUppsagning
    )

  return isBegransadRedigering
    ? values.fackligRepSaknas
    : shouldShowFackligKontaktSaknasForSkapaUppdrag
}

export default useShouldShowFackligKontaktSaknas
