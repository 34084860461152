import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { isNil } from 'ramda'
import { selectSkapaAnsokningarStepper } from '@local/Views/SkapaAnsokningar/skapaAnsokningarStepperSlice'

const useUpdateStepParam = () => {
  const history = useHistory()
  const { activeStep } = useSelector(selectSkapaAnsokningarStepper)
  const location = useLocation()

  useEffect(() => {
    if (!isNil(activeStep)) {
      const stepParam = new URLSearchParams({ step: activeStep.toString() })

      history.replace({
        pathname: location.pathname,
        search: stepParam.toString(),
      })
    }
  }, [activeStep, history, location.pathname])
}

export default useUpdateStepParam
