import { useGetAllOverenskommelseBilagaQuery } from '@local/services/API/bilagor/overenskommelseBilagaApi'
import { matchStringByRegex, UUIDRegex } from '@local/Utils/helpers'

interface IOptions {
  skip?: boolean
}

const useGetAllOverenskommelseBilaga = (options?: IOptions) => {
  const ansokanId = matchStringByRegex(window.location.pathname, UUIDRegex)
  return useGetAllOverenskommelseBilagaQuery(ansokanId, { skip: options?.skip })
}

export default useGetAllOverenskommelseBilaga
