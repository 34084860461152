import { useState } from 'react'

const useTaBortModal = () => {
  const [taBortModalIsOpen, setTaBortModalIsOpen] = useState(false)

  const openTaBortModal = () => {
    setTaBortModalIsOpen(true)
  }

  const closeTaBortModal = () => {
    setTaBortModalIsOpen(false)
  }

  return {
    taBortModalIsOpen,
    setTaBortModalIsOpen,
    openTaBortModal,
    closeTaBortModal,
  }
}

export default useTaBortModal
