import { RootState } from '@local/Store/configureStore'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface LaggTillMedarbetareState {
  excelShouldBePreset: boolean
}

const initialState: LaggTillMedarbetareState = {
  excelShouldBePreset: false,
}

export const laggTillMedarbetareSlice = createSlice({
  name: 'laggTillMedarbetare',
  initialState,
  reducers: {
    setExcelShouldBePreset: (state, action: PayloadAction<boolean>) => {
      state.excelShouldBePreset = action.payload
    },
  },
})

export const { setExcelShouldBePreset } = laggTillMedarbetareSlice.actions

export const selectLaggTillMedarbetare = (state: RootState) =>
  state.laggTillMedarbetare

export default laggTillMedarbetareSlice.reducer
