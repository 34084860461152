import { JSX, useCallback } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material'
import { usePickEpiContent } from '@local/Utils/hooks'
import Alert from '@local/Components/Alert'
import { ISkapaAnsokningarContent } from '@local/Views/SkapaAnsokningar/types/types'
import {
  useDeleteFilansokanDraftRowMutation,
  useGetFilAnsokanQuery,
} from '@local/services/API/filansokan/filAnsokanApi'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/regexes'
import { ITaBortAnsokanModal } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/TaBortAnsokanModal/types/types'

const TaBortAnsokanModal = ({
  taBortAnsokanModalState,
  setTaBortAnsokanModalState,
}: ITaBortAnsokanModal): JSX.Element => {
  const uppdragsId = matchStringByRegex(location.pathname, UUIDRegex)
  const { data, isLoading } = useGetFilAnsokanQuery(uppdragsId)

  const {
    sammanfattning: { taBortAnsokanModal },
    nagotGickFel,
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  const [deleteFilansokan, { isError: isErrorDelete }] =
    useDeleteFilansokanDraftRowMutation()

  const handleRemoveAnsokan = useCallback(() => {
    deleteFilansokan({
      uppdragsId,
      fileId: data.fileId,
      rowId: taBortAnsokanModalState.currentRowId,
    })
      .unwrap()
      .then(() => {
        if (!isErrorDelete) {
          setTaBortAnsokanModalState((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
      })
      .catch((error) => {
        console.error('Error when deleting ansokan', error)
      })
  }, [
    data.fileId,
    deleteFilansokan,
    isErrorDelete,
    setTaBortAnsokanModalState,
    taBortAnsokanModalState.currentRowId,
    uppdragsId,
  ])

  if (isLoading) {
    return
  }

  return (
    <Dialog open={taBortAnsokanModalState.open} closeAfterTransition={false}>
      <DialogTitle>{taBortAnsokanModal.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>{taBortAnsokanModal.description}</DialogContentText>
        {isErrorDelete && (
          <Alert severity="error">{nagotGickFel.heading}</Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setTaBortAnsokanModalState((prevState) => ({
              ...prevState,
              open: false,
            }))
          }}
        >
          {taBortAnsokanModal.cancelText}
        </Button>
        <Button
          variant="text"
          disabled={isErrorDelete}
          onClick={handleRemoveAnsokan}
        >
          {taBortAnsokanModal.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TaBortAnsokanModal
