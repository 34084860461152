import { JSX } from 'react'
import Spinner from '@local/Components/Spinner'
import { IKompletteraRiskansokanContent } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokan.types'
import { usePickEpiContent } from '@local/Utils/hooks'
import { IKompletteraRiskansokanFormValues } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanForm.types'
import { useFormikContext } from 'formik'
import { getUppdragSelectOptions } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanForm.helpers'
import useGetDinaKompletteraRiskUppdrag from '@local/Views/KompletteraRiskansokan/useGetDinaKompletteraRiskUppdrag'
import SelectInput from '@local/Components/SelectInput'
import { isNil } from 'ramda'
import Alert from '@local/Components/Alert'

const UppdragDropdown = (): JSX.Element => {
  const {
    data: dinaUppdrag,
    isLoading,
    isError,
  } = useGetDinaKompletteraRiskUppdrag()

  const {
    valjUppdrag: uppdragContent,
    orsakTillUppsagning,
    nagotGickFel,
  } = usePickEpiContent<IKompletteraRiskansokanContent>()

  const formikContext = useFormikContext<IKompletteraRiskansokanFormValues>()
  const { errors, touched } = formikContext

  if (isLoading) {
    return <Spinner centered />
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <SelectInput
      selectOptions={getUppdragSelectOptions(
        dinaUppdrag,
        uppdragContent,
        orsakTillUppsagning
      )}
      label={uppdragContent.dropdownLabel}
      name="nyttUppdrag"
      formikContext={formikContext}
      isError={touched.nyttUppdrag && !isNil(errors.nyttUppdrag)}
    />
  )
}

export default UppdragDropdown
