export enum KeydownKeys {
  Enter = 'Enter',
  Backspace = 'Backspace',
  Escape = 'Escape',
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Home = 'Home',
  End = 'End',
}

const allowedKeydowns = [
  KeydownKeys.Enter,
  KeydownKeys.Backspace,
  KeydownKeys.Escape,
  KeydownKeys.ArrowRight,
  KeydownKeys.ArrowLeft,
  KeydownKeys.ArrowUp,
  KeydownKeys.ArrowDown,
  KeydownKeys.Home,
  KeydownKeys.End,
]

export default allowedKeydowns
