import * as Yup from 'yup'
import {
  IAnstallningFormValues,
  IAnstallningRiskFormValues,
} from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Anstallning/Anstallning.types'
import { toTrrDateOrDefault } from '@local/Utils/helpers/formatDate'
import { AnsokanKeys } from '@local/Common.types'
import { IAnsokan } from '@local/services/API/ansokan/types'

export const anstallningRiskValidationSchema = (
  anstallningschema: Yup.ObjectSchema<IAnstallningFormValues>
): Yup.ObjectSchema<IAnstallningFormValues> =>
  anstallningschema.shape({
    [AnsokanKeys.Uppsagningsdatum]: Yup.date().notRequired(),
    [AnsokanKeys.SistaAnstallningsdatum]: Yup.date().notRequired(),
    [AnsokanKeys.Uppsagningsgrad]: Yup.number().notRequired(),
  })

export const mapAnsokanToRiskAnstallningFormValues = (
  ansokan: IAnsokan
): IAnstallningRiskFormValues => ({
  [AnsokanKeys.SistaAnstallningsdatum]: toTrrDateOrDefault(
    ansokan.anstallningsuppgifter?.sistaAnstallningsdatum
  ),
  [AnsokanKeys.Uppsagningsdatum]: toTrrDateOrDefault(
    ansokan.anstallningsuppgifter?.uppsagningsdatum
  ),
  [AnsokanKeys.Uppsagningsgrad]: ansokan.anstallningsuppgifter.uppsagningsgrad,
})

export const initialAnstallningRiskValues: IAnstallningRiskFormValues = {
  [AnsokanKeys.SistaAnstallningsdatum]: null,
  [AnsokanKeys.Uppsagningsdatum]: null,
  [AnsokanKeys.Uppsagningsgrad]: null,
}
