import { JSX } from 'react'
import {
  dayAfterDate,
  startDate,
  getLatestOccuringDate,
  calculateDate,
  today,
  octoberStart,
  yesterday,
} from '@local/Utils/helpers'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import DateInput from '@local/Components/DateInput/DateInput'
import { isNil } from 'ramda'
import { Typography } from '@mui/material'

import type { ISistaAnstallningsdatumProps } from './SistaAnstallningsdatum.types'

const SistaAnstallningsdatum = ({
  value,
  anstallningsdatum,
  uppsagningsdatum,
  lonSenastAndrad,
  formikName,
  formikContext,
  errorMsg,
  touched,
  isTioTbAnsokan,
  datumContent,
}: ISistaAnstallningsdatumProps): JSX.Element => {
  const earliestDates: Date[] = [
    startDate,
    dayAfterDate(anstallningsdatum),
    uppsagningsdatum,
    lonSenastAndrad,
  ]
  // TIO TB ansokan has to be after 2022-10-01
  if (isTioTbAnsokan) {
    earliestDates.push(octoberStart)
  }

  return (
    <div>
      <Tooltip
        variant="subtitle1"
        heading={datumContent.sistaAnstallningsdatum}
        tooltipContent={
          <Typography variant="body1">
            {isTioTbAnsokan
              ? datumContent.tooltipTioTbSistaAnstallningsdatum
              : datumContent.tooltipSistaAnstallningsdatum}
          </Typography>
        }
      />

      <DateInput
        value={value}
        error={touched && !isNil(errorMsg)}
        errorMsg={errorMsg}
        formikFieldName={formikName}
        formikContext={formikContext}
        minDate={getLatestOccuringDate(earliestDates)}
        maxDate={
          isTioTbAnsokan
            ? yesterday
            : calculateDate(today, { numberOfYearsFromDate: 3 })
        }
      />
    </div>
  )
}

export default SistaAnstallningsdatum
