import * as Yup from 'yup'
import { ILonFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Lon/Lon.types'
import { AnsokanKeys, RadioChoice } from '@local/Common.types'

export const lonRiskValidationSchema = (
  lonSchema: Yup.ObjectSchema<ILonFormValues>
): Yup.ObjectSchema<ILonFormValues> =>
  lonSchema.shape({
    [AnsokanKeys.HasAvgangsvederlag]: Yup.mixed<RadioChoice>().notRequired(),
    [AnsokanKeys.Avgangsvederlag]: Yup.number().notRequired(),
  })
