import HTMLMapper from '@local/Components/HTMLMapper'
import Tooltip from '@local/Components/Tooltip'
import Gutter from '@local/Components/Gutter'
import { Typography, useTheme } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { isNil } from 'ramda'
import { IDateInput } from '@local/Components/DateInput/Types'

const DateInput = ({
  formikContext,
  formikFieldName,
  tooltipContent,
  tooltipVariant,
  title,
  label,
  value,
  error,
  errorMsg,
  minDate,
  maxDate,
  noGutter,
  desktopModeMediaQuery,
  handleFocus,
}: IDateInput) => {
  const theme = useTheme()
  const { errors, touched, values, setFieldValue, handleBlur } = formikContext

  const isError =
    error ?? (touched?.[formikFieldName] && !isNil(errors?.[formikFieldName]))

  const errorMessage = errorMsg ?? (errors?.[formikFieldName] as string)

  const handleOnChange = (formikFieldName: string, value: Date) => {
    setFieldValue(formikFieldName, value).catch((err) => {
      console.log(err)
    })
  }

  const valueToUse = value
    ? value
    : !isNil(values?.[formikFieldName] as Date)
      ? (values?.[formikFieldName] as Date)
      : null

  return (
    <>
      {tooltipContent && title && (
        <Tooltip
          variant={tooltipVariant}
          heading={title}
          tooltipContent={<HTMLMapper body={tooltipContent.mainBody} />}
        />
      )}

      {title && !tooltipContent && (
        <>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <Gutter offset_xs={8} />
        </>
      )}

      {!title && !tooltipContent && !noGutter && <Gutter offset_xs={8} />}

      <DatePicker
        onChange={(value) => {
          handleOnChange(formikFieldName, value)
        }}
        value={valueToUse}
        label={label}
        minDate={minDate ?? new Date(null)}
        maxDate={maxDate}
        openTo="day"
        views={['year', 'day']}
        slotProps={{
          field: { clearable: true },
          textField: {
            error: isError,
            helperText: isError ? errorMessage : null,
            sx: { width: '100%' },
            name: formikFieldName,
            onBlur: handleBlur,
            onFocus: handleFocus,
          },
          layout: {
            sx: {
              display: 'block',
            },
          },
        }}
        desktopModeMediaQuery={
          desktopModeMediaQuery ?? theme.breakpoints.up('md')
        }
      />
    </>
  )
}

export default DateInput
