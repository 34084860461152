import * as Yup from 'yup'
import { ILaggTillMedarbetareFormValues } from '@local/Views/LaggTillMedarbetare/LaggTillMedarbetare.types'
import { personnummerWithCentury } from '@local/Utils/helpers/regexes'
import { validatePersonnummer } from '@local/Utils/helpers'
import { ICommonAnsokanValideringContent } from '@local/Common.types'

export const laggTillMedarbetareValidationSchema = (
  ansokanValidering: ICommonAnsokanValideringContent
): Yup.ObjectSchema<ILaggTillMedarbetareFormValues> =>
  Yup.object().shape({
    personnummer: Yup.string()
      .nullable()
      .required(ansokanValidering.personnummerSaknas)
      .matches(personnummerWithCentury, {
        message: ansokanValidering.personnummerOgiltigtFormat,
      })
      .test(
        'validatePersonnummer',
        ansokanValidering.personnummerOgiltigtFormat,
        (personnummer) => validatePersonnummer(personnummer)
      ),
  })

export const initialLaggTillMedarbetareValues: ILaggTillMedarbetareFormValues =
  {
    personnummer: null,
  }
