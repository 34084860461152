const stepsContext = {
  medarbetare: [
    'epostadress',
    'hasTjanstemannaavtal',
    'isVdEllerUndantagsgrupp',
    'overenskommelseBilagor',
  ],
  anstallning: [
    'anstallningsdatum',
    'anstallningsgrad',
    'arbetstimmarPerVecka',
    'hasSjukersattningsgrad',
    'sjukersattningsgrad',
    'uppsagningsdatum',
    'sistaAnstallningsdatum',
    'uppsagningsgrad',
  ],
  lon: [
    'avgangsvederlag',
    'hasAvgangsvederlag',
    'lonSenastAndrad',
    'manadslon',
    'provisionTantiemBelopp',
    'skiftJourOBTillaggBelopp',
  ],
}

export default stepsContext
