import { JSX, MouseEvent, useState } from 'react'
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { PopupMenuProps } from './Types'

const PopupMenu = ({
  icon = <MoreVertIcon />,
  menuItems,
  slotProps,
  ...props
}: PopupMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleIconClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleIconClick}
      >
        {icon}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            sx: {
              minWidth: 200,
              ...slotProps?.paper?.sx,
            },
            ...slotProps?.paper,
          },
        }}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        disableScrollLock
        marginThreshold={null}
        {...props}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={`menu-item-${index.toString()}-${item.label.toString()}`}
            onClick={item.linkTo ? undefined : item.onClick}
            component={item.linkTo ? 'a' : 'li'}
            href={item.linkTo}
            disabled={item.disabled}
          >
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default PopupMenu
