import { RootState } from '@local/Store/configureStore'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FormikErrors } from 'formik'

export interface SkapaAnsokningarState {
  currentStepErrors: FormikErrors<unknown>
}

const initialState: SkapaAnsokningarState = {
  currentStepErrors: {},
}

export const skapaAnsokningarSlice = createSlice({
  name: 'skapaAnsokningar',
  initialState,
  reducers: {
    setCurrentStepErrors: (
      state,
      action: PayloadAction<FormikErrors<unknown>>
    ) => {
      state.currentStepErrors = action.payload
    },
  },
})

export const { setCurrentStepErrors } = skapaAnsokningarSlice.actions

export const selectSkapaAnsokningar = (state: RootState) =>
  state.skapaAnsokningar

export default skapaAnsokningarSlice.reducer
