let numberOfExecutedIterations: number = 0
let timeout: NodeJS.Timeout

export const waitForElementToRender = (
  elementId: string,
  callback: () => void
) => {
  const element = document.querySelector(elementId)

  if (numberOfExecutedIterations < 50) {
    numberOfExecutedIterations++
    timeout = setTimeout(() => {
      if (element) {
        numberOfExecutedIterations = 0
        callback()
      } else {
        waitForElementToRender(elementId, callback)
      }
    }, 10)
  } else {
    clearTimeout(timeout)
    numberOfExecutedIterations = 0
  }
}
