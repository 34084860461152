import { JSX } from 'react'
import {
  dayAfterDate,
  getEarliestOccuringDate,
  calculateDate,
  yesterday,
} from '@local/Utils/helpers'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import DateInput from '@local/Components/DateInput/DateInput'
import { isNil } from 'ramda'
import { Typography } from '@mui/material'

import type { IUppsagningsdatumProps } from './Uppsagningsdatum.types'

const Uppsagningsdatum = ({
  value,
  errorMsg,
  formikName,
  anstallningsdatum,
  sistaAnstallningsdatum,
  formikContext,
  touched,
  datumContent,
}: IUppsagningsdatumProps): JSX.Element => (
  <div>
    <Tooltip
      variant="subtitle1"
      heading={datumContent.uppsagningsdatum}
      tooltipContent={
        <Typography variant="body1">
          {datumContent.tooltipUppsagningsdatum}
        </Typography>
      }
    />

    <DateInput
      value={value}
      error={touched && !isNil(errorMsg)}
      errorMsg={errorMsg}
      formikFieldName={formikName}
      formikContext={formikContext}
      minDate={dayAfterDate(anstallningsdatum)}
      maxDate={getEarliestOccuringDate([
        sistaAnstallningsdatum,
        calculateDate(yesterday, { numberOfMonthsFromDate: 6 }),
      ])}
    />
  </div>
)

export default Uppsagningsdatum
