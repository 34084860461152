import {
  IRequestedUpdates,
  ISkapaAnsokanApiModel,
  ISkapaAnsokanFormValues,
} from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import { useCallback, useState } from 'react'

const useFormikWatcherCallback = () => {
  const [requestedUpdates, setRequestedUpdates] = useState<IRequestedUpdates>({
    updatesToPatch: null,
    updatesToStore: null,
  })

  const formikWatcherCallback = useCallback(
    (
      valuesToPatch: Partial<ISkapaAnsokanApiModel>,
      valuesToStore: Partial<ISkapaAnsokanFormValues>
    ) => {
      setRequestedUpdates({
        updatesToPatch: {
          ...requestedUpdates.updatesToPatch,
          ...valuesToPatch,
        },
        updatesToStore: {
          ...requestedUpdates.updatesToStore,
          ...valuesToStore,
        },
      })
    },
    [requestedUpdates.updatesToPatch, requestedUpdates.updatesToStore]
  )

  return { formikWatcherCallback, requestedUpdates, setRequestedUpdates }
}

export default useFormikWatcherCallback
