import { JSX } from 'react'
import Highlightbox from '@local/Components/Highlightbox'
import Gutter from '@local/Components/Gutter'
import { usePickEpiContent } from '@local/Utils/hooks'
import { IUppdragFormWrapperContent } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import { useIsBegransadRedigering } from '@local/Views/UppdragFormWrapper/UppdragForm/hooks'
import { Typography } from '@mui/material'

const BegransadRedigering = (): JSX.Element => {
  const { begransadRedigering } =
    usePickEpiContent<IUppdragFormWrapperContent>()
  const isBegransadRedigering = useIsBegransadRedigering()

  if (!isBegransadRedigering) {
    return null
  }

  return (
    <>
      <Highlightbox>
        <Typography variant="h5" gutterBottom>
          {begransadRedigering.heading}
        </Typography>
        <Typography variant="body1">
          {begransadRedigering.description}
        </Typography>
      </Highlightbox>
      <Gutter offset_xs={32} />
    </>
  )
}

export default BegransadRedigering
