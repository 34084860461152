import { baseApi } from '@local/services/API/baseApi'
import {
  ISkickaKompletteringPayloadModel,
  IUppdragKompletteraRiskApiModel,
} from '@local/services/API/kompletteraRisk/types'
import { IGetDinaUppdragProps } from '@local/services/API/uppdrag/types'

export const kompletteraRiskApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getDinaKompletteraRiskUppdrag: builder.query<
      IUppdragKompletteraRiskApiModel[],
      IGetDinaUppdragProps
    >({
      query: ({ userSub, organisationsnummer }) =>
        `kund/${userSub}/organisation/${organisationsnummer}/uppdrag/kompletterarisk`,
      providesTags: [{ type: 'uppdrag', id: 'LIST' }],
    }),
    skickaKomplettering: builder.mutation<
      void,
      ISkickaKompletteringPayloadModel
    >({
      query: ({ ansokanId, nuvarandeUppdragId, body }) => ({
        url: `ansokan/uppdrag/${nuvarandeUppdragId}/ansokan/${ansokanId}/kompletterarisk`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (_response, _error, payload) => [
        { type: 'uppdrag', id: 'LIST' },
        { type: 'uppdrag', id: payload.nuvarandeUppdragId },
        { type: 'ansokan', id: 'GROUPED_LIST' },
        { type: 'ansokan', id: payload.ansokanId },
      ],
    }),
  }),
})

export const {
  useGetDinaKompletteraRiskUppdragQuery,
  useSkickaKompletteringMutation,
} = kompletteraRiskApi
