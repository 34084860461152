import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'
import { useIsRedigeraUppdrag } from '@local/Views/UppdragFormWrapper/UppdragForm/hooks'

const useIsBegransadRedigering = (): boolean => {
  const { data: uppdrag } = useGetUppdrag()
  const isRedigera = useIsRedigeraUppdrag()

  return isRedigera && uppdrag.antalMedarbetare > 0
}

export default useIsBegransadRedigering
