import { JSX } from 'react'
import { useFormikContext } from 'formik'
import Gutter from '@local/Components/Gutter'
import HTMLMapper from '@local/Components/HTMLMapper'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import { Grid2 as Grid, Typography } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/hooks'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import { formatIdentityNumber } from '@local/Utils/helpers'
import Highlightbox from '@local/Components/Highlightbox/Highlightbox'
import TextInput from '@local/Components/TextInput/TextInput'
import { isNil } from 'ramda'
import { IHighlightBoxListItem } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Medarbetare/Medarbetarinformation/types'
import Alert from '@local/Components/Alert'
import { AnsokanKeys } from '@local/Common.types'

const Medarbetarinformation = (): JSX.Element => {
  const { data: ansokan, isError } = useGetAnsokan()

  const formikContext = useFormikContext<ISkapaAnsokanFormValues>()
  const { values, touched, errors } = formikContext

  const {
    medarbetare: { medarbetarinformation, tooltipEPostadress },
    nagotGickFel,
  } = usePickEpiContent<ISkapaAnsokanContent>()

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  const highlightBoxItems: IHighlightBoxListItem[] = [
    {
      label: medarbetarinformation.personnummer,
      value:
        ansokan?.personuppgifter &&
        formatIdentityNumber(ansokan.personuppgifter.personnummer),
    },
    {
      label: medarbetarinformation.fornamn,
      value: ansokan.personuppgifter.fornamn,
    },
    {
      label: medarbetarinformation.efternamn,
      value: ansokan.personuppgifter.efternamn,
    },
  ]

  return (
    <article>
      <Highlightbox>
        {highlightBoxItems.map((item) => (
          <Grid container key={item.value} gap={1}>
            <Grid size={{ xs: 'auto' }}>
              <Typography variant="subtitle1">{item.label}</Typography>
            </Grid>
            <Grid size={{ xs: 'auto' }} key={item.value}>
              <Typography variant="body1">{item.value}</Typography>
            </Grid>
          </Grid>
        ))}
      </Highlightbox>

      <Gutter offset_xs={32} />

      <Tooltip
        variant="subtitle1"
        heading={medarbetarinformation.epost}
        tooltipContent={<HTMLMapper body={tooltipEPostadress.mainBody} />}
      />

      <TextInput
        withTrim
        inputMode="email"
        formikContext={formikContext}
        label={medarbetarinformation.epost}
        value={values.epostadress}
        error={touched?.epostadress && !isNil(errors?.epostadress)}
        errorMsg={errors?.epostadress}
        placeholder={medarbetarinformation.epostPlaceholder}
        name={AnsokanKeys.Epostadress}
      />
    </article>
  )
}

export default Medarbetarinformation
