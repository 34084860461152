import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import FolderList from '@local/Components/FolderList'
import { Avatar } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { isNil } from 'ramda'
import { LaggTillMedarbetareFormTypes } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/types/types'
import { IRadioButtonCard } from '@local/Components/RadioButtonCard/types/types'

const StyledRadioButtonCard = styled('div')(({ theme }) => ({
  '& .MuiToggleButton-root': {
    color: theme.palette.text.primary,
    textTransform: 'none',

    '&.Mui-selected': {
      backgroundColor: theme.palette.surface?.green,
      border: `2px solid ${theme.palette.success.main}`,
    },

    '&.has-errors': {
      border: `2px solid ${theme.palette.error.main}`,
    },

    '& .MuiListItem-root': {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
  },
}))

const RadioButtonCard = ({
  formikContext,
  value,
  name,
  selected,
  title,
  subtitle,
  icon,
  ariaLabel,
  dataGtmLabel,
}: IRadioButtonCard) => {
  const { errors, touched, setFieldValue } = formikContext
  const theme = useTheme()

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: LaggTillMedarbetareFormTypes
  ) => {
    setFieldValue(name, value).catch((err) => {
      console.log(err)
    })
  }

  const isError = touched?.[name] && !isNil(errors?.[name])

  return (
    <StyledRadioButtonCard>
      <ToggleButton
        fullWidth
        value={value}
        selected={selected}
        aria-label={ariaLabel ?? title}
        data-gtm-label={dataGtmLabel}
        onChange={handleChange}
        className={isError ? 'has-errors' : null}
      >
        <FolderList
          title={title}
          subtitle={subtitle}
          icon={
            <Avatar
              sx={{
                width: 48,
                height: 48,
                bgcolor: selected
                  ? theme.palette.success.main
                  : theme.palette.surface?.green,
              }}
            >
              {selected ? (
                <CheckIcon
                  color={selected ? 'inherit' : 'success'}
                  sx={{ fontSize: 24 }}
                />
              ) : (
                icon
              )}
            </Avatar>
          }
        />
      </ToggleButton>
    </StyledRadioButtonCard>
  )
}

export default RadioButtonCard
