import { useAppDispatch } from '@local/Store/configureStore'
import useGetColumnHeaders from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/hooks/useGetColumnHeaders'
import {
  selectErrorStepper,
  setCurrentErrorIndex,
  setErrors,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/ErrorStepper/errorStepperSlice'
import {
  IFilAnsokanRowFormik,
  IFilansokanError,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/types/types'
import { ISkapaAnsokningarFormValues } from '@local/Views/SkapaAnsokningar/types/types'
import { FormikErrors, useFormikContext } from 'formik'
import { isEmpty, isNil } from 'ramda'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const useSetFilansokningarErrors = () => {
  const dispatch = useAppDispatch()
  const { columnHeaders } = useGetColumnHeaders()
  const { errors: formikErrors, values: formikValues } =
    useFormikContext<ISkapaAnsokningarFormValues>()
  const { currentErrorIndex } = useSelector(selectErrorStepper)

  useEffect(() => {
    if (
      isNil(formikErrors.filansokningar) ||
      isEmpty(formikErrors.filansokningar)
    ) {
      dispatch(setErrors([]))
    } else if (typeof formikErrors.filansokningar === 'string') {
      dispatch(
        setErrors([
          {
            id: 'filansokningar',
            error: formikErrors.filansokningar,
            rowIndex: null,
          },
        ])
      )
      dispatch(setCurrentErrorIndex(0))
    } else {
      const errorsFlat: IFilansokanError[] = (
        formikErrors.filansokningar as FormikErrors<IFilAnsokanRowFormik>[]
      )
        ?.map((filansokanErrors, index) => {
          if (isNil(filansokanErrors)) {
            return []
          } else {
            return Object.entries(filansokanErrors).map((entries) => ({
              id: entries[0],
              error: entries[1],
              rowIndex: index,
            }))
          }
        })
        .reduce((acc, current) => [...acc, ...current])
        .sort(
          (a, b) =>
            columnHeaders.indexOf(
              columnHeaders.find((header) => header.field === a.id)
            ) -
            columnHeaders.indexOf(
              columnHeaders.find((header) => header.field === b.id)
            )
        )
        .sort((a, b) => a.rowIndex - b.rowIndex)

      const isErrorLast = errorsFlat.length - currentErrorIndex === 0
      const subtractor = isErrorLast ? 1 : errorsFlat.length - currentErrorIndex
      const nextErrorIndex = errorsFlat.length - subtractor

      dispatch(setCurrentErrorIndex(nextErrorIndex))
      dispatch(setErrors(errorsFlat))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formikErrors, formikValues])
}

export default useSetFilansokningarErrors
