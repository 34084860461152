import * as Yup from 'yup'
import { email } from '@local/Utils/helpers/regexes'
import { IMedarbetareFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Medarbetare/Medarbetare.types'
import {
  AnsokanKeys,
  ICommonAnsokanValideringContent,
  RadioChoice,
} from '@local/Common.types'

export const medarbetareValidationSchema = (
  ansokanValidering: ICommonAnsokanValideringContent,
  kraverOverenskommelseBilaga: boolean
): Yup.ObjectSchema<IMedarbetareFormValues> =>
  Yup.object().shape({
    [AnsokanKeys.Epostadress]: Yup.string()
      .nullable()
      .required(ansokanValidering.epostSaknas)
      .matches(email, {
        message: ansokanValidering.epostOgiltig,
      }),
    [AnsokanKeys.OverenskommelseBilagor]: Yup.array().when([], () => {
      if (kraverOverenskommelseBilaga) {
        return Yup.array().min(1, ansokanValidering.overenskommelseBilagaSaknas)
      }
    }),
    [AnsokanKeys.HasTjanstemannaavtal]: Yup.mixed<RadioChoice>()
      .nullable()
      .required(ansokanValidering.tjanstemannaavtalSaknas),
    [AnsokanKeys.IsVdEllerUndantagsgrupp]: Yup.mixed<RadioChoice>()
      .nullable()
      .required(ansokanValidering.undantagsgruppSaknas),
  })
