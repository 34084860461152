import { JSX, useEffect, useMemo } from 'react'
import SkapaAnsokningarForm from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm'
import { usePickEpiContent } from '@local/Utils/hooks'
import { ISkapaAnsokningarContent } from '@local/Views/SkapaAnsokningar/types/types'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/regexes'
import {
  useGetFilAnsokanDraftQuery,
  useGetFilAnsokanQuery,
} from '@local/services/API/filansokan/filAnsokanApi'
import Spinner from '@local/Components/Spinner'
import Alert from '@local/Components/Alert'
import Gutter from '@local/Components/Gutter'
import { isNil } from 'ramda'
import { useSelector } from 'react-redux'
import {
  selectLaggTillMedarbetare,
  setExcelShouldBePreset,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/laggTillMedarbetareSlice'
import { useAppDispatch } from '@local/Store/configureStore'
import { skapaAnsokningarValidationSchema } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/SkapaAnsokningarForm.schema'
import { useCheckFileTimestamp } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/hooks'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'

const SkapaAnsokningar = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const { excelShouldBePreset } = useSelector(selectLaggTillMedarbetare)

  const content = usePickEpiContent<ISkapaAnsokningarContent>()

  const uppdragsId = matchStringByRegex(location.pathname, UUIDRegex)

  const { data: uppdrag, isLoading: isLoadingUppdrag } = useGetUppdrag()

  const {
    data: filansokan,
    isLoading: isLoadingFilAnsokan,
    isError,
  } = useGetFilAnsokanQuery(uppdragsId)

  const {
    data: filansokanRows,
    isLoading: isLoadingFilAnsokanDraft,
    isError: isErrorDraft,
  } = useGetFilAnsokanDraftQuery(
    { fileId: filansokan?.fileId, uppdragsId },
    { skip: isNil(filansokan?.fileId) }
  )

  const { arbitraryTimeHasPassedSinceUpload } = useCheckFileTimestamp()

  const memoizedSkapaAnsokningarSchema = useMemo(
    () =>
      skapaAnsokningarValidationSchema(
        content,
        uppdrag,
        arbitraryTimeHasPassedSinceUpload
      ),
    [content, uppdrag, arbitraryTimeHasPassedSinceUpload]
  )

  useEffect(() => {
    if (!isNil(filansokan?.fileId)) {
      dispatch(setExcelShouldBePreset(true))
    }
  }, [dispatch, filansokan])

  if (isLoadingFilAnsokan || isLoadingFilAnsokanDraft || isLoadingUppdrag) {
    return <Spinner centered />
  }

  return (
    <>
      {(isError || isErrorDraft) && (
        <>
          <Gutter offset_xs={16} />
          <Alert severity="error" centered>
            {content.nagotGickFel.heading}
          </Alert>
        </>
      )}

      {!isError && !isErrorDraft && (
        <SkapaAnsokningarForm
          skapaAnsokningarSchema={memoizedSkapaAnsokningarSchema}
          filansokan={filansokan}
          filansokanRows={filansokanRows}
          excelShouldBePreset={excelShouldBePreset}
        />
      )}
    </>
  )
}

export default SkapaAnsokningar
