import React, { JSX, useEffect, useRef, useState } from 'react'
import Accordion from '@local/Components/Accordion/Accordion'
import { useSelector } from 'react-redux'
import { selectRemovableCard } from '@local/Components/RemovableCard/removableCardSlice'
import { IRemovableCard } from '@local/Components/RemovableCard/types'
import Header from '@local/Components/RemovableCard/Header/Header'
import ButtonArea from '@local/Components/RemovableCard/ButtonArea'
import Divider from '@local/Components/Divider'
import Gutter from '@local/Components/Gutter'
import TaBortModal from '@local/Components/RemovableCard/TaBortModal'

const RemovableCard: React.FunctionComponent<
  React.PropsWithChildren<IRemovableCard>
> = ({
  headerContent,
  buttonAreaContent,
  taBortModalContent,
  isExpanded,
  shouldScroll,
  attributes,
  cardHasErrors,
  handleLaggTill,
  handleRemove,
  toggleIsExpanded,
  setShouldScroll,
  children,
}): JSX.Element => {
  const [taBortModalOpen, setTaBortModalOpen] = useState(false)
  const { shouldScrollToCard } = useSelector(selectRemovableCard)
  const scrollElement = useRef<HTMLDivElement>(null)

  const scrollToElement = () => {
    scrollElement?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  useEffect(() => {
    if (shouldScrollToCard) {
      scrollToElement()
    }
  }, [shouldScrollToCard])

  useEffect(() => {
    if (shouldScroll) {
      scrollToElement()
      setShouldScroll(false)
    }
  }, [shouldScroll, setShouldScroll])

  return (
    <div {...attributes} ref={scrollElement}>
      <Accordion
        expanded={isExpanded}
        onChange={toggleIsExpanded}
        heading={<Header {...headerContent} cardHasErrors={cardHasErrors} />}
        disableGutters
        ariaControls={`removable-card-content`}
        ariaControlsId={`removable-card-header`}
      >
        {children}

        <Divider offset_xs={48} offset_md={64} />

        <ButtonArea
          buttonAreaContent={buttonAreaContent}
          handleLaggTill={handleLaggTill}
          toggleTaBortModal={() => {
            setTaBortModalOpen(true)
          }}
          cardHasErrors={cardHasErrors}
        />
      </Accordion>

      <Gutter offset_xs={32} />

      <TaBortModal
        modalOpen={taBortModalOpen}
        closeModal={() => {
          setTaBortModalOpen(false)
        }}
        handleRemove={handleRemove}
        taBortModalContent={taBortModalContent}
      />
    </div>
  )
}

export default RemovableCard
