import { styled } from '@mui/material/styles'
import { Divider as MuiDivider, dividerClasses } from '@mui/material'

type OffsetSize =
  | 0
  | 4
  | 8
  | 12
  | 16
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64
  | 68
  | 72
  | 76
  | 80
  | 84
  | 88
  | 92
  | 96

interface IDivider {
  offset_xs?: OffsetSize
  offset_sm?: OffsetSize
  offset_md?: OffsetSize
  offset_lg?: OffsetSize
  offset_xl?: OffsetSize
  noSpacing?: boolean
}

const StyledDivider = styled('div')<IDivider>(
  ({ theme, offset_xs, offset_sm, offset_md, offset_lg, offset_xl }) => ({
    display: 'flex',
    alignItems: 'center',
    height: offset_xs,

    [`& .${dividerClasses.root}`]: {
      flex: 1,
    },

    [theme.breakpoints.up('sm')]: {
      height: offset_sm,
    },
    [theme.breakpoints.up('md')]: {
      height: offset_md,
    },
    [theme.breakpoints.up('lg')]: {
      height: offset_lg,
    },
    [theme.breakpoints.up('xl')]: {
      height: offset_xl,
    },
  })
)

const Divider = ({
  offset_xs = 32,
  offset_sm,
  offset_md,
  offset_lg,
  offset_xl,
  noSpacing = false,
}: IDivider) =>
  noSpacing ? (
    <MuiDivider />
  ) : (
    <StyledDivider
      offset_xs={offset_xs}
      offset_sm={offset_sm}
      offset_md={offset_md}
      offset_lg={offset_lg}
      offset_xl={offset_xl}
    >
      <MuiDivider />
    </StyledDivider>
  )

export default Divider
