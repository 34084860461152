import { JSX, useState } from 'react'
import { Button, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { usePickEpiContent } from '@local/Utils/hooks'
import { ISkapaAnsokningarContent } from '@local/Views/SkapaAnsokningar/types/types'
import AvbrytFilansokanModal from '@local/Views/SkapaAnsokningar/SkapaAnsokningarHeader/AvbrytFilansokanModal'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'

const StyledSkapaAnsokningarHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '& > p:first-of-type': {
    marginLeft: theme.spacing(1),
  },

  '& > *:not(:last-child)': {
    marginRight: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3),
    },
  },
}))

const SkapaAnsokningarHeader = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false)

  const { arbetsgivaransokanLinks, fullscreenHeader } =
    usePickEpiContent<ISkapaAnsokningarContent>()

  const { data: uppdrag, isLoading } = useGetUppdrag()

  if (isLoading) {
    return null
  }

  return (
    <>
      <StyledSkapaAnsokningarHeader>
        <Typography variant="body1">{uppdrag.arbetsstalle.namn}</Typography>
        <Button
          variant="outlined"
          href={arbetsgivaransokanLinks.vanligaFragorUrl}
        >
          {fullscreenHeader.hjalp}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setModalOpen(true)
          }}
        >
          {fullscreenHeader.avbryt}
        </Button>
      </StyledSkapaAnsokningarHeader>

      <AvbrytFilansokanModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  )
}

export default SkapaAnsokningarHeader
