import * as Yup from 'yup'
import {
  IFile,
  ILaggTillMedarbetareFormValues,
  ILaggTillMedarbetareValideringContent,
  LaggTillMedarbetareFormTypes,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/types/types'

export const laggTillMedarbetareValidationSchema = (
  laggTillMedarbetareValidering: ILaggTillMedarbetareValideringContent
) =>
  Yup.object().shape({
    laggTillMedarbetareChoice: Yup.mixed<LaggTillMedarbetareFormTypes>()
      .nullable()
      .required(
        laggTillMedarbetareValidering.laggTillMedarbetareChoiceRequired
      ),
    file: Yup.object<IFile>()
      .shape({
        id: Yup.string().nullable(),
        name: Yup.string().nullable(),
      })
      .nullable()
      .when(
        ['laggTillMedarbetareChoice'],
        (
          [laggTillMedarbetareChoice]: [LaggTillMedarbetareFormTypes],
          schema
        ) => {
          if (
            laggTillMedarbetareChoice ===
            LaggTillMedarbetareFormTypes.LaggTillMedarbetareExcel
          ) {
            return schema.required(
              laggTillMedarbetareValidering.laddaUppFilRequired
            )
          }
        }
      ),
  })

export const laggTillMedarbetareInitialState: ILaggTillMedarbetareFormValues = {
  laggTillMedarbetareChoice: null,
  file: null,
}
