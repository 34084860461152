import { JSX, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import DataGrid from '@local/Components/DataGrid'
import { GridRowModesModel, useGridApiRef } from '@mui/x-data-grid-pro'
import {
  useGetColumnHeaders,
  useGridHandlers,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/hooks'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import { ISkapaAnsokningarFormValues } from '@local/Views/SkapaAnsokningar/types/types'
import { AnsokanKeys } from '@local/Common.types'
import { isEmpty } from 'ramda'
import ErrorStepper from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/ErrorStepper'
import Gutter from '@local/Components/Gutter'
import { useAppDispatch } from '@local/Store/configureStore'
import TaBortAnsokanModal from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/TaBortAnsokanModal'
import Toaster from '@local/Components/Toaster'
import VarningEjSparadData from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/VarningEjSparadData'
import { setIsAnyRowBeingEdited } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/sammanfattningSlice'

const StyledSammanfattning = styled('div')(() => ({
  position: 'relative',
  height: 'calc(100vh - 454px)', //454px represents the totalt height of the other page elements combined
}))

const Sammanfattning = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const apiRef = useGridApiRef()

  const { initialValues, values, validateForm, setTouched } =
    useFormikContext<ISkapaAnsokningarFormValues>()

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const {
    columnHeaders,
    rowModesModel,
    setRowModesModel,
    handleCancelClick,
    handleSaveClick,
    handleEditClick,
    taBortAnsokanModalState,
    setTaBortAnsokanModalState,
    filansokanErrorToaster,
    setFilansokanErrorToaster,
    isAnyRowInEditMode,
  } = useGetColumnHeaders(apiRef)

  const {
    handleCellKeyDown,
    handleCellClick,
    handleCellDoubleClick,
    handleCellMouseDown,
  } = useGridHandlers(
    rowModesModel,
    isAnyRowInEditMode,
    handleCancelClick,
    handleSaveClick,
    handleEditClick
  )

  useEffect(() => {
    validateForm()
      .then((validationErrors) => {
        if (!isEmpty(validationErrors)) {
          setTouched(
            setNestedObjectValues<FormikTouched<ISkapaAnsokningarFormValues>>(
              validationErrors,
              true
            )
          )?.catch((err) => {
            console.log(err)
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [setTouched, validateForm, initialValues])

  useEffect(() => {
    dispatch(setIsAnyRowBeingEdited(isAnyRowInEditMode))
  }, [dispatch, isAnyRowInEditMode])

  return (
    <>
      <VarningEjSparadData />

      <ErrorStepper apiRef={apiRef} />

      <Gutter offset_xs={24} />

      <StyledSammanfattning>
        <DataGrid
          columns={columnHeaders}
          rows={values.filansokningar}
          rowModesModel={rowModesModel}
          setRowModesModel={setRowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          pinnedColumns={{ left: [AnsokanKeys.Namn], right: ['action'] }}
          apiRef={apiRef}
          onCellClick={handleCellClick}
          onCellKeyDown={handleCellKeyDown}
          onCellDoubleClick={handleCellDoubleClick}
          slotProps={{
            cell: {
              onMouseDown: handleCellMouseDown,
            },
          }}
        />
      </StyledSammanfattning>

      <TaBortAnsokanModal
        taBortAnsokanModalState={taBortAnsokanModalState}
        setTaBortAnsokanModalState={setTaBortAnsokanModalState}
      />
      <Toaster
        open={filansokanErrorToaster.open}
        message={filansokanErrorToaster.message}
        setOpen={() => {
          setFilansokanErrorToaster({ ...filansokanErrorToaster, open: false })
        }}
      />
    </>
  )
}

export default Sammanfattning
