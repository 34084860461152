import { IEditorialBlock, ILinkBlock } from '@local/Common.types'
import { IFilePickerErrorContent } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/LaddaUppFil/types/types'

export interface ILaggTillMedarbetareContent {
  validering: ILaggTillMedarbetareValideringContent
  laggTillViaFormular: ILaggTillViaFormularContent
  laggTillViaExcel: ILaggTillViaExcelContent
  laddaUppFil: ILaddaUppFilContent
  saHarGorDuEnAnsokan: IEditorialBlock
  taBortFilModal: ITaBortFilModal
  excelmall: ILinkBlock
  filePickerError: IFilePickerErrorContent
}
export interface ITaBortFilModal {
  heading: string
  description: string
  cancelText: string
  confirmText: string
}

export interface ILaggTillMedarbetareFormValues {
  laggTillMedarbetareChoice: LaggTillMedarbetareFormTypes
  file: IFile
}

export interface IFile {
  id: string
  name: string
  uploadedAt: Date
}

export interface ILaggTillMedarbetareValideringContent {
  laggTillMedarbetareChoiceRequired: string
  laddaUppFilRequired: string
}

export interface ILaggTillViaFormularContent {
  heading: string
  subtitle: string
}

export interface ILaggTillViaExcelContent {
  heading: string
  subtitle: string
}

export interface ILaddaUppFilContent {
  heading: string
  uploadButtonText: string
  clearButtonText: string
  description: string
  tooltip: string
  nagotGickFel: string
}

export enum LaggTillMedarbetareFormTypes {
  LaggTillMedarbetareFormular = 'laggTillMedarbetareFormular',
  LaggTillMedarbetareExcel = 'laggTillMedarbetareExcel',
}
