import { AnsokanKeys, IPatchBodyApiModel, PatchOps } from '@local/Common.types'
import { trrDateUTC } from '@local/Utils/helpers/formatDate'
import { getDropdownSelectOptionValue } from '@local/Utils/helpers/Forms/Forms.helpers'
import {
  ISkapaAnsokanApiModel,
  ISkapaAnsokanFormValues,
} from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import { omit } from 'ramda'

/**
 * Maps formik watcher form values to API model for sending PATCH - Test operations to server
 */
export const getMappedSkapaAnsokanFormValues = (
  values: Partial<ISkapaAnsokanFormValues>
): Partial<ISkapaAnsokanApiModel> => ({
  ...omit([AnsokanKeys.OverenskommelseBilagor], values),
  anstallningsdatum: values.anstallningsdatum
    ? trrDateUTC(values.anstallningsdatum).toISOString()
    : null,
  sistaAnstallningsdatum: values.sistaAnstallningsdatum
    ? trrDateUTC(values.sistaAnstallningsdatum).toISOString()
    : null,
  uppsagningsdatum: values.uppsagningsdatum
    ? trrDateUTC(values.uppsagningsdatum).toISOString()
    : null,
  sjukersattningsgrad: values.sjukersattningsgrad
    ? (getDropdownSelectOptionValue(values.sjukersattningsgrad) as number)
    : null,
  avgangsvederlag: values.avgangsvederlag,
  jourBelopp: values.skiftJourOBTillaggBelopp,
  lonerevisionsdatum: values.lonSenastAndrad
    ? trrDateUTC(values.lonSenastAndrad).toISOString()
    : null,
  manadslon: values.manadslon,
  provisionBelopp: values.provisionTantiemBelopp,
})

export const mapAnsokanValuesToPatchModel = (
  values: Partial<ISkapaAnsokanApiModel>,
  prevValues: Partial<ISkapaAnsokanFormValues>
): IPatchBodyApiModel[] => {
  const mappedPreviousValues = getMappedSkapaAnsokanFormValues(prevValues)

  return Object.keys(values)
    .map((key) => [
      {
        op: PatchOps.Test,
        path: `/${key.toLocaleLowerCase()}`,
        value: mappedPreviousValues[key as keyof ISkapaAnsokanApiModel],
      },
      {
        op: PatchOps.Replace,
        path: `/${key.toLocaleLowerCase()}`,
        value: values[key as keyof ISkapaAnsokanApiModel],
      },
    ])
    .reduce((acc, current) => [...acc, ...current])
}
