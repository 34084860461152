import { JSX } from 'react'
import { Alert, Button, Grid2 as Grid } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Gutter from '@local/Components/Gutter'
import { usePickEpiContent } from '@local/Utils/hooks'
import { IUppdragContent } from '@local/Views/Uppdrag/Uppdrag.types'
import { IOmRisk } from '@local/Views/Uppdrag/AnsokanList/AnsokanCard/OmRisk/types'

const OmRisk = ({ row, redigeraButtonCallback }: IOmRisk): JSX.Element => {
  const {
    ansokanDetaljer: { omRisk },
    arbetsgivaransokanLinks: { kompletteraRiskUrl },
  } = usePickEpiContent<IUppdragContent>()
  return (
    <>
      <Alert severity="info">{omRisk.heading}</Alert>

      <Gutter offset_xs={16} />

      <Grid container spacing={1}>
        <Grid size={{ xs: 'auto' }}>
          <Button
            size="small"
            variant="contained"
            startIcon={<AddIcon />}
            href={`${kompletteraRiskUrl}${row.id}/`}
          >
            {omRisk.kompletteraButtonLabel}
          </Button>
        </Grid>
        {row.isRedigerbar && (
          <Grid size={{ xs: 'auto' }}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                redigeraButtonCallback(row)
              }}
            >
              {omRisk.redigeraButtonLabel}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default OmRisk
