import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import { useFormikContext } from 'formik'

const useFackligKontaktHasErrors = () => {
  const { errors, touched, values } = useFormikContext<IUppdragFormValues>()

  return (
    errors.fackligaKontakter &&
    touched.fackligaKontakter &&
    values.fackligaKontakter?.length <= 0
  )
}

export default useFackligKontaktHasErrors
