import { OrsakerTillUppsagning } from '@local/Common.types'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'

const useGetUppdragIsRisk = () => {
  const { data } = useGetUppdrag()

  return data?.orsakTillUppsagning === OrsakerTillUppsagning.Risk
}

export default useGetUppdragIsRisk
