import { AnsokanState } from '@local/Common.types'
import { RootState } from '@local/Store/configureStore'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IAnsokanListState {
  activeTab: AnsokanState
}

const initialState: IAnsokanListState = {
  activeTab: AnsokanState.Utkast,
}

export const ansokanListSlice = createSlice({
  name: 'ansokanList',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<AnsokanState>) => {
      state.activeTab = action.payload
    },
    resetActiveTab: (state) => {
      state.activeTab = initialState.activeTab
    },
  },
})

export const { setActiveTab, resetActiveTab } = ansokanListSlice.actions

export const selectAnsokanList = (state: RootState) => state.ansokanList

export default ansokanListSlice.reducer
