import { useAppDispatch } from '@local/Store/configureStore'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import {
  resetState,
  selectSkapaAnsokan,
  setFormIsDirty,
  setValues,
} from '@local/Views/SkapaAnsokan/skapaAnsokanSlice'
import { equals } from 'ramda'
import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { skapaAnsokanStoreMiddleware } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/skapaAnsokanStoreMiddleware'

const useReduxStore = (
  initialValues: ISkapaAnsokanFormValues,
  values: ISkapaAnsokanFormValues
) => {
  const { formIsDirty, values: storedValues } = useSelector(selectSkapaAnsokan)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setValues(initialValues))
    return () => {
      dispatch(resetState())
    }
  }, [dispatch, initialValues])

  useEffect(() => {
    if (equals(storedValues, values)) {
      dispatch(setFormIsDirty(false))
    } else {
      dispatch(setFormIsDirty(true))
    }
  }, [storedValues, values, dispatch])

  const setStoredValuesAfterPatch = useCallback(
    (valuesToStore: Partial<ISkapaAnsokanFormValues>) => {
      dispatch(
        setValues({
          ...storedValues,
          ...skapaAnsokanStoreMiddleware(valuesToStore),
        })
      )
    },
    [dispatch, storedValues]
  )

  return { setStoredValuesAfterPatch, storedValues, formIsDirty }
}

export default useReduxStore
