import { JSX } from 'react'
import { useFormikContext } from 'formik'
import CommonOverenskommelseBilaga from '@local/Components/OverenskommelseBilaga'
import { IKompletteraRiskansokanFormValues } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanForm.types'
import { IKompletteraRiskansokanContent } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokan.types'
import { usePickEpiContent } from '@local/Utils/hooks'
import { generateErrorMessage } from '@local/Utils/helpers/Forms/Forms.helpers'
import { getUppdragKraverOverenskommelseBilaga } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanForm.helpers'
import { Grid2 as Grid, Typography } from '@mui/material'
import Gutter from '@local/Components/Gutter'

const OverenskommelseBilaga = (): JSX.Element => {
  const { errors, touched, values, setFieldValue } =
    useFormikContext<IKompletteraRiskansokanFormValues>()

  const { laddaUppBilaga, overenskommelseBilaga, nagotGickFel } =
    usePickEpiContent<IKompletteraRiskansokanContent>()

  if (!getUppdragKraverOverenskommelseBilaga(values)) {
    return null
  }

  return (
    <Grid container role="status">
      <Grid size={{ xs: 'grow', md: 8 }}>
        <Typography variant="h6">{overenskommelseBilaga.heading}</Typography>

        <Gutter offset_xs={12} />

        <Typography variant="body1">
          {overenskommelseBilaga.description}
        </Typography>

        <CommonOverenskommelseBilaga
          content={laddaUppBilaga}
          formikFieldName="overenskommelseBilagor"
          files={values.overenskommelseBilagor}
          errorMessage={generateErrorMessage({
            touched: touched.overenskommelseBilagor !== undefined,
            errorMsg: errors.overenskommelseBilagor as string,
          })}
          setFieldValue={setFieldValue}
          nagotGickFel={nagotGickFel}
        />
      </Grid>
    </Grid>
  )
}

export default OverenskommelseBilaga
