import { selectSkapaAnsokningarStepper } from '@local/Views/SkapaAnsokningar/skapaAnsokningarStepperSlice'
import { useTrackVirtualView } from '@trr/gtm-tracking'
import { isNil } from 'ramda'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const SkapaAnsokningarTracker = () => {
  const stepTrigger = useTrackVirtualView('Medarbetaransökningar')
  const { activeStep } = useSelector(selectSkapaAnsokningarStepper)

  useEffect(() => {
    if (!isNil(activeStep)) {
      stepTrigger(`Step: ${String(activeStep)}`)
    }
  }, [stepTrigger, activeStep])

  return <></>
}

export default SkapaAnsokningarTracker
