import { RootState } from '@local/Store/configureStore'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ISammanfattningState {
  isAnyRowBeingEdited: boolean
}

const initialState: ISammanfattningState = {
  isAnyRowBeingEdited: false,
}

export const sammanfattningSlice = createSlice({
  name: 'sammanfattning',
  initialState,
  reducers: {
    setIsAnyRowBeingEdited: (state, action: PayloadAction<boolean>) => {
      state.isAnyRowBeingEdited = action.payload
    },
  },
})

export const { setIsAnyRowBeingEdited } = sammanfattningSlice.actions

export const selectSammanfattning = (state: RootState) => state.sammanfattning

export default sammanfattningSlice.reducer
