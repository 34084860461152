import { JSX } from 'react'
import { styled } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import { useStepper } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/SkapaAnsokningarStepper/hooks'

const StyledStepperContent = styled('div')(({ theme }) => ({
  padding: `0 ${theme.spacing(3)}`,

  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(6)} 0 ${theme.spacing(6)}`,
  },
}))

const SkapaAnsokningarStepper = (): JSX.Element => {
  const { stepper, currentStepBody, currentStepHeader } = useStepper()

  return (
    <>
      {stepper}

      <Gutter offset_xs={16} />

      <StyledStepperContent>
        {currentStepHeader}

        <Gutter offset_xs={16} />

        {currentStepBody}
      </StyledStepperContent>
    </>
  )
}

export default SkapaAnsokningarStepper
