import { JSX } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import Gutter from '@local/Components/Gutter'

import { ITaBortModalProps } from './types/'

const TaBortModal = ({
  closeModal,
  modalOpen,
  handleRemove,
  taBortModalContent,
}: ITaBortModalProps): JSX.Element => (
  <Dialog onClose={closeModal} open={modalOpen} closeAfterTransition={false}>
    <DialogTitle>{taBortModalContent.heading}</DialogTitle>
    <DialogContent>
      <DialogContentText>{taBortModalContent.description}</DialogContentText>
      <Gutter offset_xs={24} />
    </DialogContent>

    <DialogActions>
      <Button variant="text" onClick={closeModal}>
        {taBortModalContent.cancelText}
      </Button>
      <Button variant="text" onClick={handleRemove}>
        {taBortModalContent.confirmText}
      </Button>
    </DialogActions>
  </Dialog>
)

export default TaBortModal
