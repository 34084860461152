import { useDispatch } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import removableCardReducer from '@local/Components/RemovableCard/removableCardSlice'
import skapaAnsokanNavigationReducer from '@local/Views/SkapaAnsokan/SkapaAnsokanNavigation/skapaAnsokanNavigationSlice'
import skapaAnsokningarReducer from '@local/Views/SkapaAnsokningar/skapaAnsokningarSlice'
import laggTillMedarbetareReducer from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/laggTillMedarbetareSlice'
import skapaAnsokningarStepperReducer from '@local/Views/SkapaAnsokningar/skapaAnsokningarStepperSlice'
import sammanfattningReducer from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/sammanfattningSlice'
import errorStepperReducer from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/ErrorStepper/errorStepperSlice'
import skapaAnsokanReducer from '@local/Views/SkapaAnsokan/skapaAnsokanSlice'
import personuppgifterReducer from '@local/Views/LaggTillMedarbetare/Personuppgifter/personuppgifterSlice'
import ansokanListReducer from '@local/Views/Uppdrag/AnsokanList/ansokanListSlice'
import { baseApi } from '@local/services/API/baseApi'
import formikWatchSliceReducer from '@local/Utils/helpers/formikWatchSlice'
import { setupListeners } from '@reduxjs/toolkit/query'

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  ['formikWatch']: formikWatchSliceReducer,
  ['skapaAnsokanNavigation']: skapaAnsokanNavigationReducer,
  ['skapaAnsokningar']: skapaAnsokningarReducer,
  ['laggTillMedarbetare']: laggTillMedarbetareReducer,
  ['skapaAnsokningarStepper']: skapaAnsokningarStepperReducer,
  ['sammanfattning']: sammanfattningReducer,
  ['errorStepper']: errorStepperReducer,
  ['personuppgifter']: personuppgifterReducer,
  ['skapaAnsokan']: skapaAnsokanReducer,
  ['removableCard']: removableCardReducer,
  ['ansokanList']: ansokanListReducer,
})

export const setupStore = (preloadedState?: DeepPartial<RootState>) => {

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware({ serializableCheck: false }).concat([
        baseApi.middleware,
      ]),
    preloadedState,
  })
  setupListeners(store.dispatch)

  return store
}

export const store = setupStore()

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
