import { JSX, useState } from 'react'
import { usePickEpiContent } from '@local/Utils/hooks'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'
import { IUppdragContent } from '@local/Views/Uppdrag/Uppdrag.types'
import { useDeleteUppdragMutation } from '@local/services/API/uppdrag/uppdragApi'
import { useGetDialogContent } from '@local/Views/Uppdrag/Header/TaBortUppdragModal/hooks/'

import { ITaBortUppdragModal } from './TaBortUppdragModal.types'

const TaBortUppdragModal = ({
  modalOpen,
  closeModal,
}: ITaBortUppdragModal): JSX.Element => {
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false)
  const shouldTrack = useIsFeatureEnabled('gtm.enabled.kundansokan')
  const trackButtonClick = useTrackCustomClick()

  const { data: uppdrag } = useGetUppdrag()

  const {
    arbetsgivaransokanLinks,
    taBortUppdragModal: { heading, errorHeader, confirmLabel, cancelLabel },
  } = usePickEpiContent<IUppdragContent>()

  const canRemoveUppdrag = uppdrag?.antalMedarbetare === 0

  const history = useHistory()

  const [deleteUppdrag] = useDeleteUppdragMutation()

  const dialogContent = useGetDialogContent({
    shouldShowErrorMessage,
  })

  const handleOnConfirm = () => {
    if (shouldTrack) {
      trackButtonClick('Ta bort uppdrag modal', {
        label: 'Ta bort uppdrag',
      })
    }
    deleteUppdrag(uppdrag.id)
      .unwrap()
      .then(() => {
        history.push(arbetsgivaransokanLinks.dinaUppdragUrl)
      })
      .catch((error) => {
        console.log(error)
        setShouldShowErrorMessage(true)
      })
  }

  const handleOnCancel = () => {
    if (shouldTrack) {
      trackButtonClick('Ta bort uppdrag modal', {
        label: 'Avbryt',
      })
    }
    closeModal()
    setShouldShowErrorMessage(false)
  }

  if (!uppdrag) {
    return
  }

  return (
    <Dialog
      onClose={handleOnCancel}
      open={modalOpen}
      maxWidth={'sm'}
      fullWidth
      closeAfterTransition={false}
    >
      <DialogTitle>
        {canRemoveUppdrag && !uppdrag?.isLocked ? heading : errorHeader}
      </DialogTitle>

      <DialogContent sx={{ py: 0 }}>{dialogContent}</DialogContent>

      <DialogActions>
        <Button variant="text" onClick={handleOnCancel} data-trackable="false">
          {cancelLabel}
        </Button>
        {canRemoveUppdrag && !uppdrag?.isLocked && (
          <Button
            variant="text"
            onClick={handleOnConfirm}
            disabled={shouldShowErrorMessage}
            data-trackable="false"
          >
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default TaBortUppdragModal
