import * as Yup from 'yup'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import { IAnsokan } from '@local/services/API/ansokan/types'
import { AnsokanType } from '@local/Common.types'

import { medarbetareValidationSchema } from './Medarbetare/Medarbetare.schema'
import { anstallningValidationSchema } from './Anstallning/Anstallning.schema'
import { lonValidationSchema } from './Lon/Lon.schema'

export const skapaAnsokanValidationSchema = (
  content: ISkapaAnsokanContent,
  ansokan: IAnsokan
) => {
  const isRisk = ansokan.isRisk
  const isTB = ansokan.type === AnsokanType.omstallningTidsbegransadAnstallning

  return Yup.object()
    .concat(
      medarbetareValidationSchema(
        content.medarbetare.ansokanValidering,
        ansokan.kraverOverenskommelseBilaga
      )
    )
    .concat(
      anstallningValidationSchema(
        content.anstallning.ansokanValidering,
        content.anstallning.datumValidering,
        isRisk,
        isTB
      )
    )
    .concat(
      lonValidationSchema(
        content.lon.ansokanValidering,
        content.lon.datumValidering,
        isRisk,
        isTB
      )
    )
}
