import EditNoteIcon from '@mui/icons-material/EditNote'
import DescriptionIcon from '@mui/icons-material/Description'
import Gutter from '@local/Components/Gutter'
import { useFormikContext } from 'formik'
import {
  ISkapaAnsokningarContent,
  ISkapaAnsokningarFormValues,
} from '@local/Views/SkapaAnsokningar/types/types'
import { isNil } from 'ramda'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import { LaggTillMedarbetareFormTypes } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/types/types'
import RadioButtonCard from '@local/Components/RadioButtonCard/RadioButtonCard'
import { usePickEpiContent } from '@local/Utils/hooks'

const RadioButtonCards = () => {
  const {
    laggTillMedarbetare: { laggTillViaFormular, laggTillViaExcel },
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  const formikContext = useFormikContext<ISkapaAnsokningarFormValues>()

  const { values, touched, errors } = formikContext

  const isError =
    touched?.laggTillMedarbetareChoice &&
    !isNil(errors?.laggTillMedarbetareChoice)

  return (
    <>
      <RadioButtonCard
        formikContext={formikContext}
        value={LaggTillMedarbetareFormTypes.LaggTillMedarbetareFormular}
        name="laggTillMedarbetareChoice"
        selected={
          values.laggTillMedarbetareChoice ===
          LaggTillMedarbetareFormTypes.LaggTillMedarbetareFormular
        }
        title={laggTillViaFormular.heading}
        subtitle={laggTillViaFormular.subtitle}
        icon={<EditNoteIcon color="success" />}
        ariaLabel={laggTillViaFormular.heading}
        dataGtmLabel="Lägg till via formulär"
      />

      <Gutter offset_xs={16} />

      <RadioButtonCard
        formikContext={formikContext}
        value={LaggTillMedarbetareFormTypes.LaggTillMedarbetareExcel}
        name="laggTillMedarbetareChoice"
        selected={
          values.laggTillMedarbetareChoice ===
          LaggTillMedarbetareFormTypes.LaggTillMedarbetareExcel
        }
        title={laggTillViaExcel.heading}
        subtitle={laggTillViaExcel.subtitle}
        icon={<DescriptionIcon color="success" />}
        ariaLabel={laggTillViaExcel.heading}
        dataGtmLabel="Lägg till via excel"
      />

      {isError && (
        <>
          <Gutter offset_xs={32} />

          <FormErrorMessage>
            {errors.laggTillMedarbetareChoice}
          </FormErrorMessage>
        </>
      )}
    </>
  )
}

export default RadioButtonCards
