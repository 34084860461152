import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'

export interface IEditorialBlock {
  /**
   * HTML-formatted string
   */
  mainBody: string
}

export enum RadioChoice {
  Ja = 'true',
  Nej = 'false',
}

export interface IKeyValue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export interface ISelectOption {
  label: string
  value: string | number
  id?: string
}

export type ISelectOptionFormikWrapper = ISelectOption | string
/**
 * Returns the stored time value in milliseconds since midnight, January 1, 1970 UTC.
 */
export type IDateTimestamp = number

/**
 * Returns the Date as a ISO formatted string (.toISOString())
 */
export type IDateTimestring = string

export interface IOrsakTillUppsagningContent extends IKeyValue {
  arbetsbrist: string
  konkurs: string
  risk: string
  visstidsanstallningslut: string
  egenbegaran: string
  overenskommelse: string
  sjukdom: string
  annan: string
  tidsbegransadanstallning: string
}

export enum OrsakerTillUppsagning {
  TidsbegransadAnstallning = 'tidsbegransadanstallning',
  Arbetsbrist = 'arbetsbrist',
  Overenskommelse = 'overenskommelse',
  Konkurs = 'konkurs',
  Risk = 'risk',
  Sjukdom = 'sjukdom',
}

export enum TypAvSamband {
  SammaJuridiskaPerson = 'sammajuridiskaperson',
  OverlatetForetag = 'overlatetforetag',
  InhyrdViaBemanning = 'inhyrdviabemanning',
  SammaKoncern = 'sammakoncern',
}

export enum FackligKontaktOrsakerTillUppsagning {
  Arbetsbrist = 'arbetsbrist',
  Overenskommelse = 'overenskommelse',
  Sjukdom = 'sjukdom',
}

export enum FackligKontaktSaknasOrsakerTillUppsagning {
  Overenskommelse = 'overenskommelse',
  Sjukdom = 'sjukdom',
}

export enum MedarbetareState {
  IngenAnsokan = 'ingenansokan',
  InskickadAnsokan = 'inskickadansokan',
  UtkastAnsokan = 'utkastansokan',
}

export interface IBeslutContent extends IKeyValue {
  bedomningpagar: string
  godkand: string
  avslag: string
  annanomstallningsleverantor: string
  inskickad: string
}

export enum Beslut {
  BedomningPagar = 'bedomningpagar',
  Godkand = 'godkand',
  Avslag = 'avslag',
  AnnanOmstallningsleverantor = 'annanomstallningsleverantor',
  Inskickad = 'inskickad',
}

export enum Status {
  Draft = 'draft',
  NeedsGranskning = 'needsgranskning',
  RedigerasAvArbetsgivare = 'redigerasavarbetsgivare',
}

export interface ILaddaUppBilaga {
  heading: string
  uploadButtonText: string
  fileDeletedError: string
  fileSizeLimitExceeded: string
  fileUploadError: string
  fileExtensionError: string
  fileUploadCannotReadFileError: string
  description: string
  tooltip: string
}

export interface INagotGickFelContent {
  heading: string
}

export interface ICommonContent {
  ja: string
  nej: string
  timmarSuffix: string
  ejFaststalltLabel: string
}

export type IPatchGickFel = INagotGickFelContent

export interface IVarningLamnaSidan {
  description: string
}

export enum PatchOps {
  Test = 'test',
  Replace = 'replace',
  Add = 'add',
  Remove = 'remove',
}

export interface IPatchBodyApiModel {
  op: PatchOps
  path: string
  value?: unknown
}

export interface IArbetsgivaransokanLinks {
  dinaUppdragUrl: string
  kompletteraRiskUrl: string
  laggTillMedarbetareUrl: string
  redigeraUppdragUrl: string
  saAnsokerDuUrl: string
  skapaAnsokanUrl: string
  skapaAnsokningarUrl: string
  skapaUppdragUrl: string
  uppdragUrl: string
  vanligaFragorUrl: string
}

export interface ICommonDatumValideringContent {
  warningMinDate: string
  warningMaxDate: string
  warningDateIsRequired: string
  warningDateIsInvalid: string
}

export interface ICommonAnsokanValideringContent {
  anstallningsdatumSaknas: string
  anstallningsgradInvalidFormat: string
  anstallningsgradMaxAmountRequired: string
  anstallningsgradMinAmountRequired: string
  anstallningsgradSaknas: string
  arbetstimmarMaxAmountExceeded: string
  arbetstimmarSaknas: string
  arbetstimmarWrongDecimals: string
  avgangsvederlagBeloppSaknas: string
  avgangsvederlagInvalidFormat: string
  avgangsvederlagMaxAmountExceeded: string
  avgangsvederlagMinAmountRequired: string
  avgangsvederlagSaknas: string
  epostOgiltig: string
  epostSaknas: string
  lonSenastAndradSaknas: string
  manadslonInvalidFormat: string
  manadslonMaxAmountExceeded: string
  manadslonMinAmountRequired: string
  manadslonSaknas: string
  overenskommelseBilagaSaknas: string
  personnummerOgiltigtFormat: string
  personnummerSaknas: string
  provisionTantiemBeloppInvalidFormat: string
  provisionTantiemBeloppMaxAmountExceeded: string
  provisionTantiemBeloppMinAmountRequired: string
  sistaAnstallningsdatumMaxAmountExceeded: string
  sistaAnstallningsdatumSaknas: string
  sjukersattningsgradBeloppSaknas: string
  sjukersattningsgradSaknas: string
  skiftJourOBTillaggBeloppInvalidFormat: string
  skiftJourOBTillaggBeloppMaxAmountExceeded: string
  skiftJourOBTillaggBeloppMinAmountRequired: string
  tjanstemannaavtalSaknas: string
  undantagsgruppSaknas: string
  uppsagningsdatumSaknas: string
  uppsagningsgradInvalidFormat: string
  uppsagningsgradMaxAmountRequired: string
  uppsagningsgradMinAmountRequired: string
  uppsagningsgradSaknas: string
}

export type FormikWatcherValues = Omit<
  ISkapaAnsokanFormValues,
  'sambandsanstallningar'
>

export interface ILinkBlock {
  heading: string
  url: string
}

export enum AnsokanKeys {
  Anstallningsdatum = 'anstallningsdatum',
  Anstallningsgrad = 'anstallningsgrad',
  ArbetstimmarPerVecka = 'arbetstimmarPerVecka',
  Avgangsvederlag = 'avgangsvederlag',
  CurrentUserIsSameAsApplicant = 'currentUserIsSameAsApplicant',
  Epostadress = 'epostadress',
  HasMedarbetareAnsokanAtCurrentUppdrag = 'hasMedarbetareAnsokanAtCurrentUppdrag',
  HasAvgangsvederlag = 'hasAvgangsvederlag',
  HasSjukersattningsgrad = 'hasSjukersattningsgrad',
  HasTjanstemannaavtal = 'hasTjanstemannaavtal',
  IsVdEllerUndantagsgrupp = 'isVdEllerUndantagsgrupp',
  LonSenastAndrad = 'lonSenastAndrad',
  Manadslon = 'manadslon',
  Namn = 'namn',
  Obestamd = 'obestamd',
  OverenskommelseBilagor = 'overenskommelseBilagor',
  Personnummer = 'personnummer',
  ProvisionTantiemBelopp = 'provisionTantiemBelopp',
  SistaAnstallningsdatum = 'sistaAnstallningsdatum',
  Sjukersattningsgrad = 'sjukersattningsgrad',
  SkiftJourOBTillaggBelopp = 'skiftJourOBTillaggBelopp',
  Tjanstemannaavtal = 'tjanstemannaavtal',
  Uppsagningsdatum = 'uppsagningsdatum',
  Uppsagningsgrad = 'uppsagningsgrad',
  Vd = 'vd',
}

export enum FilAnsokanKeys {
  Anstallningsdatum = 'anstallningsdatum',
  Anstallningsgrad = 'anstallningsgrad',
  ArbetstimmarPerVecka = 'arbetstimmarpervecka',
  Avgangsvederlag = 'avgangsvederlag',
  BonusProvisionTantiem = 'bonusprovisiontantiem',
  CurrentUserIsSameAsApplicant = 'currentuserissameasapplicant',
  Epost = 'epost',
  HasMedarbetareAnsokanAtCurrentUppdrag = 'hasmedarbetareansokanatcurrentuppdrag',
  LonSenastAndrad = 'lonsenastandrad',
  Manadslon = 'manadslon',
  Namn = 'namn',
  Obestamd = 'obestamd',
  Personnummer = 'personnummer',
  SistaAnstallningsdag = 'sistaanstallningsdag',
  Sjukersattningsgrad = 'sjukersattningsgrad',
  SkiftJourOb = 'skiftjourob',
  Tjanstemannaavtal = 'tjanstemannaavtal',
  Uppsagningsdatum = 'uppsagningsdatum',
  Uppsagningsgrad = 'uppsagningsgrad',
  Vd = 'vd',
}

export interface IArbetsgivaransokanStatus extends IKeyValue {
  heading: string
  okand: string
  ejkomplett: string
  ejinskickad: string
  inskickad: string
  bedomd: string
  preskriberad: string
  kompletteringkravs: string
  redigerad: string
}

export enum ArbetsgivaransokanStatus {
  Okand = 'okand',
  EjKomplett = 'ejkomplett',
  EjInskickad = 'ejinskickad',
  Inskickad = 'inskickad',
  Bedomd = 'bedomd',
  Preskriberad = 'preskriberad',
  KompletteringKravs = 'kompletteringkravs',
  Redigerad = 'redigerad',
}

export enum AnsokanType {
  omstallningTillsvidareanstallning = 'omstallningtillsvidareanstallning',
  omstallningTillsvidareanstallningRisk = 'omstallningtillsvidareanstallningrisk',
  omstallningTidsbegransadAnstallning = 'omstallningtidsbegransadanstallning',
}

export interface IAnsokanState extends IKeyValue {
  bedomd: string
  fullstandig: string
  inskickad: string
  utkast: string
  kompletterarisk: string
}
export enum AnsokanState {
  Bedomd = 'bedomd',
  Fullstandig = 'fullstandig',
  Inskickad = 'inskickad',
  Utkast = 'utkast',
  KompletteraRisk = 'kompletterarisk',
}
