import { JSX } from 'react'
import HTMLMapper from '@local/Components/HTMLMapper'
import Alert from '@local/Components/Alert'
import { IUppdragContent } from '@local/Views/Uppdrag/Uppdrag.types'
import usePickEpiContent from '@local/Utils/hooks/usePickEpiContent'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'
import { DialogContentText } from '@mui/material'

const useGetDialogContent = ({
  shouldShowErrorMessage,
}: {
  shouldShowErrorMessage: boolean
}): JSX.Element => {
  const { data: uppdrag } = useGetUppdrag()

  const {
    nagotGickFel,
    taBortUppdragModal: { description, uppdragetIsLocked },
    uppdragKanInteTasBortModal,
  } = usePickEpiContent<IUppdragContent>()
  const canRemoveUppdrag = uppdrag?.antalMedarbetare === 0

  if (shouldShowErrorMessage) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  if (uppdrag?.isLocked) {
    return <DialogContentText>{uppdragetIsLocked}</DialogContentText>
  }

  if (canRemoveUppdrag) {
    return <DialogContentText>{description}</DialogContentText>
  }

  return <HTMLMapper body={uppdragKanInteTasBortModal.mainBody} />
}

export default useGetDialogContent
