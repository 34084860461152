import React from 'react'
import { styled } from '@mui/material/styles'
import Paper, { PaperProps } from '@mui/material/Paper'

interface IHighlightBox extends PaperProps {
  padding_xs?: number
  padding_sm?: number
  padding_md?: number
  padding_lg?: number
  padding_xl?: number
}

const StyledHighlightbox = styled((props: IHighlightBox) => (
  <Paper elevation={0} {...props} component="article" aria-label="Inforuta" />
))(({ theme, padding_xs, padding_sm, padding_md, padding_lg, padding_xl }) => ({
  padding: padding_xs,
  backgroundColor: theme.palette.surface?.orange,
  maxWidth: 752,

  [theme.breakpoints.up('sm')]: {
    padding: padding_sm,
  },

  [theme.breakpoints.up('md')]: {
    padding: padding_md,
  },

  [theme.breakpoints.up('lg')]: {
    padding: padding_lg,
  },

  [theme.breakpoints.up('xl')]: {
    padding: padding_xl,
  },
}))

const Highlightbox: React.FunctionComponent<
  React.PropsWithChildren<IHighlightBox>
> = ({
  padding_xs = 24,
  padding_sm,
  padding_md = 40,
  padding_lg,
  padding_xl,
  children,
  ...props
}) => (
  <StyledHighlightbox
    padding_xs={padding_xs}
    padding_sm={padding_sm}
    padding_md={padding_md}
    padding_lg={padding_lg}
    padding_xl={padding_xl}
    {...props}
  >
    {children}
  </StyledHighlightbox>
)

export default Highlightbox
