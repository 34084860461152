import { appendComma } from '@local/Utils/helpers'
import { IFackligKontakt } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import {
  IForetagApiModel,
  IAdminApiModel,
} from '@local/services/API/foretag/types'

export const getFormattedForetag = (foretagModel: IForetagApiModel) =>
  appendComma(foretagModel.ort) +
  appendComma(foretagModel.adress) +
  foretagModel.namn

export const getFormattedAdmins = (administratorer: IAdminApiModel[]) =>
  administratorer?.map(({ namn: label, id: value, ...rest }) => ({
    label,
    value,
    ...rest,
  }))

export const generateEmptyFackligKontakt = (): IFackligKontakt => ({
  key: crypto.randomUUID(),
  fornamn: '',
  efternamn: '',
  epostadress: '',
  telefon: '',
  fackforbund: null,
})
