import { GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid-pro'
import { useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/hooks'
import { AnsokanKeys, OrsakerTillUppsagning } from '@local/Common.types'
import {
  ISkapaAnsokningarContent,
  ISkapaAnsokningarFormValues,
} from '@local/Views/SkapaAnsokningar/types/types'
import {
  baseColumnSettings,
  getCommonCellDateSettings,
  getCommonColumnSettings,
  renderBaseDateInput,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/helpers/common'
import {
  calculateDate,
  dayAfterDate,
  dayBeforeDate,
  getEarliestOccuringDate,
  getLatestOccuringDate,
  startDate,
  today,
  yesterday,
} from '@local/Utils/helpers'
import { toTrrDateOrDefault } from '@local/Utils/helpers/formatDate'
import { IFilAnsokanRowFormik } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/types/types'
import { useMemo } from 'react'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'

const useGetDateCells = () => {
  const { data: uppdrag } = useGetUppdrag()

  const {
    sammanfattning: { columnHeaders: columnHeadersContent },
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  const formikContext = useFormikContext<ISkapaAnsokningarFormValues>()

  const { values, errors } = formikContext

  const anstallningsdatum: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.Anstallningsdatum,
        columnHeadersContent,
        values,
        errors
      ),
      ...getCommonCellDateSettings(values, AnsokanKeys.Anstallningsdatum),
      renderEditCell: (params: GridRenderEditCellParams) => {
        const currentRow: IFilAnsokanRowFormik =
          params.row as IFilAnsokanRowFormik

        return renderBaseDateInput({
          ansokanKey: AnsokanKeys.Anstallningsdatum,
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
          minDate: startDate,
          maxDate: getEarliestOccuringDate([
            yesterday,
            dayBeforeDate(
              values.filansokningar[currentRow.index]?.uppsagningsdatum
            ),
            dayBeforeDate(
              values.filansokningar[currentRow.index]?.sistaAnstallningsdatum
            ),
            toTrrDateOrDefault(
              values.filansokningar[currentRow.index]?.lonSenastAndrad
            ),
          ]),
        })
      },
    }),
    [columnHeadersContent, errors, values]
  )

  const uppsagningsdatum: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.Uppsagningsdatum,
        columnHeadersContent,
        values,
        errors
      ),
      ...getCommonCellDateSettings(values, AnsokanKeys.Uppsagningsdatum),
      renderEditCell: (params: GridRenderEditCellParams) => {
        const currentRow: IFilAnsokanRowFormik =
          params.row as IFilAnsokanRowFormik

        return renderBaseDateInput({
          ansokanKey: AnsokanKeys.Uppsagningsdatum,
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
          minDate: dayAfterDate(
            values.filansokningar[currentRow.index]?.anstallningsdatum
          ),
          maxDate: getEarliestOccuringDate([
            values.filansokningar[currentRow.index]?.sistaAnstallningsdatum,
            calculateDate(yesterday, { numberOfMonthsFromDate: 6 }),
          ]),
        })
      },
    }),
    [columnHeadersContent, errors, values]
  )

  const sistaAnstallningsdatum: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.SistaAnstallningsdatum,
        columnHeadersContent,
        values,
        errors
      ),
      ...getCommonCellDateSettings(values, AnsokanKeys.SistaAnstallningsdatum),
      renderEditCell: (params: GridRenderEditCellParams) => {
        const currentRow: IFilAnsokanRowFormik =
          params.row as IFilAnsokanRowFormik

        return renderBaseDateInput({
          ansokanKey: AnsokanKeys.SistaAnstallningsdatum,
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
          minDate: getLatestOccuringDate([
            startDate,
            dayAfterDate(
              values.filansokningar[currentRow.index]?.anstallningsdatum
            ),
            values.filansokningar[currentRow.index]?.uppsagningsdatum,
            values.filansokningar[currentRow.index]?.lonSenastAndrad,
          ]),
          maxDate:
            uppdrag.orsakTillUppsagning ===
            OrsakerTillUppsagning.TidsbegransadAnstallning
              ? yesterday
              : calculateDate(today, { numberOfYearsFromDate: 3 }),
        })
      },
    }),
    [columnHeadersContent, errors, uppdrag?.orsakTillUppsagning, values]
  )

  const lonSenastAndrad: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.LonSenastAndrad,
        columnHeadersContent,
        values,
        errors
      ),
      ...getCommonCellDateSettings(values, AnsokanKeys.LonSenastAndrad),
      renderEditCell: (params: GridRenderEditCellParams) => {
        const currentRow: IFilAnsokanRowFormik =
          params.row as IFilAnsokanRowFormik

        return renderBaseDateInput({
          ansokanKey: AnsokanKeys.LonSenastAndrad,
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
          minDate: toTrrDateOrDefault(
            values.filansokningar[currentRow.index]?.anstallningsdatum
          ),
          maxDate: getEarliestOccuringDate([
            today,
            toTrrDateOrDefault(
              values.filansokningar[currentRow.index]?.sistaAnstallningsdatum
            ),
          ]),
        })
      },
    }),
    [columnHeadersContent, errors, values]
  )

  return {
    anstallningsdatum,
    uppsagningsdatum,
    sistaAnstallningsdatum,
    lonSenastAndrad,
  }
}

export default useGetDateCells
