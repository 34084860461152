import { JSX } from 'react'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import { useFormikContext } from 'formik'
import Gutter from '@local/Components/Gutter'
import HTMLMapper from '@local/Components/HTMLMapper'
import { IUppdragFormWrapperContent } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import { usePickEpiContent } from '@local/Utils/hooks'
import { useIsBegransadRedigering } from '@local/Views/UppdragFormWrapper/UppdragForm/hooks'

const FackligKontaktInfoBox = (): JSX.Element => {
  const isBegransadRedigering = useIsBegransadRedigering()
  const { values } = useFormikContext<IUppdragFormValues>()
  const { fackligKontaktInfo } = usePickEpiContent<IUppdragFormWrapperContent>()

  return (
    isBegransadRedigering &&
    !values.fackligRepSaknas && (
      <>
        <HTMLMapper body={fackligKontaktInfo.mainBody} />

        <Gutter offset_xs={32} />
      </>
    )
  )
}

export default FackligKontaktInfoBox
