import { FormikProps, withFormik } from 'formik'
import Gutter from '@local/Components/Gutter'
import Divider from '@local/Components/Divider'
import { useDirtyCheckPrompt } from '@trr/react-use-dirty-check-prompt'
import {
  IUppdragFormProps,
  IUppdragFormValues,
} from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import Uppdragsinfo from '@local/Views/UppdragFormWrapper/UppdragForm/Uppdragsinfo'
import OrsakTillUppsagning from '@local/Views/UppdragFormWrapper/UppdragForm/OrsakTillUppsagning'
import FackligKontakt from '@local/Views/UppdragFormWrapper/UppdragForm/FackligKontakt'
import LaggTillAdmin from '@local/Views/UppdragFormWrapper/UppdragForm/LaggTillAdmin'
import UppdragFormSubmit from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragFormSubmit'
import { IUppdragFormWrapperContent } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import {
  initialUppdragValues,
  uppdragValidationSchema,
} from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.schema'
import { mapUppdragApiValuesToForm } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragFormValuesMapper'
import BegransadRedigering from '@local/Views/UppdragFormWrapper/UppdragForm/BegransadRedigering/BegransadRedigering'
import { Grid2 as Grid, Typography } from '@mui/material'
import Highlightbox from '@local/Components/Highlightbox'

const UppdragForm = ({
  dirty,
  content,
  uppdrag,
}: IUppdragFormProps & FormikProps<IUppdragFormValues>) => {
  const { routerPrompt } = useDirtyCheckPrompt(
    dirty,
    content.varningLamnaSidan.description
  )

  if (uppdrag?.isLocked) {
    return (
      <Grid container>
        <Grid size={{ md: 8 }}>
          <Highlightbox>
            <Typography variant="h5" gutterBottom>
              {content.uppdateringProcessas.heading}
            </Typography>
            <Typography variant="body1">
              {content.uppdateringProcessas.description}
            </Typography>
          </Highlightbox>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container>
      {routerPrompt}
      <Grid size={{ xs: 'grow', md: 8 }}>
        <BegransadRedigering />

        <Uppdragsinfo />

        <Divider offset_md={96} />

        <OrsakTillUppsagning />

        <FackligKontakt />

        <Divider offset_md={96} />

        <LaggTillAdmin />

        <Gutter offset_xs={48} />

        <UppdragFormSubmit />
      </Grid>
    </Grid>
  )
}

const UppdragFormik = withFormik<IUppdragFormProps, IUppdragFormValues>({
  validateOnBlur: true,
  mapPropsToValues: ({ uppdrag }) =>
    uppdrag ? mapUppdragApiValuesToForm(uppdrag) : initialUppdragValues,
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({ content }: { content: IUppdragFormWrapperContent }) =>
    uppdragValidationSchema(content),
  displayName: 'UppdragForm',
})(UppdragForm)

export default UppdragFormik
