import { JSX } from 'react'
import HTMLMapper from '@local/Components/HTMLMapper'
import Gutter from '@local/Components/Gutter'
import UppdragDropdown from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/ValjUppdrag/UppdragDropdown'
import { IKompletteraRiskansokanContent } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokan.types'
import { usePickEpiContent } from '@local/Utils/hooks'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import { Button, Grid2 as Grid } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'

const ValjUppdrag = (): JSX.Element => {
  const {
    valjUppdrag,
    tooltipValjUppdrag,
    arbetsgivaransokanLinks: { skapaUppdragUrl },
  } = usePickEpiContent<IKompletteraRiskansokanContent>()

  return (
    <>
      <Tooltip
        heading={valjUppdrag.heading}
        tooltipContent={<HTMLMapper body={tooltipValjUppdrag.mainBody} />}
      />

      <Gutter offset_xs={16} />

      <Button
        variant="outlined"
        href={skapaUppdragUrl}
        startIcon={<AddCircleIcon />}
      >
        {valjUppdrag.skapaNyttUppdragButtonLabel}
      </Button>

      <Gutter offset_xs={32} />

      <Grid container>
        <Grid size={{ xs: 'grow', md: 8 }}>
          <UppdragDropdown />
        </Grid>
      </Grid>
    </>
  )
}

export default ValjUppdrag
