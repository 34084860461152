import { JSX } from 'react'
import Gutter from '@local/Components/Gutter'
import { Grid2 as Grid, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { getFormattedForetag } from '@local/Views/UppdragFormWrapper/UppdragForm/Helpers'
import { IUppdragFormWrapperContent } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import { usePickEpiContent } from '@local/Utils/hooks'
import { useGetForetagByOrganisationsnummerQuery } from '@local/services/API/foretag/foretagApi'
import { useIsBegransadRedigering } from '@local/Views/UppdragFormWrapper/UppdragForm/hooks'
import { useUser } from '@trr/app-shell-data'
import TextInput from '@local/Components/TextInput'
import { ISelectOption } from '@local/Common.types'
import { isNil } from 'ramda'
import SelectInput from '@local/Components/SelectInput'
import Spinner from '@local/Components/Spinner/Spinner'
import Alert from '@local/Components/Alert'

const Uppdragsinfo = (): JSX.Element => {
  const { kundProperties } = useUser()

  const { uppdragsnamn, arbetsplats, nagotGickFel } =
    usePickEpiContent<IUppdragFormWrapperContent>()

  const formikContext = useFormikContext<IUppdragFormValues>()
  const { touched, errors } = formikContext

  const isBegransadRedigering = useIsBegransadRedigering()

  const {
    data: foretag,
    isLoading: isLoadingForetag,
    isError: isErrorForetag,
  } = useGetForetagByOrganisationsnummerQuery(
    kundProperties?.foretag?.organizationNumber
  )

  const selectOptions: ISelectOption[] = foretag?.map((foretagModel) => ({
    label: getFormattedForetag(foretagModel),
    value: foretagModel.id,
  }))

  const isErrorArbetsplats = touched.arbetsplats && !isNil(errors.arbetsplats)

  if (isErrorForetag) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <Grid container>
      <Grid size={{ xs: 'grow', md: 8 }}>
        <Gutter offset_xs={8} />
        <Typography variant="subtitle1">{uppdragsnamn.heading}</Typography>

        <Gutter offset_xs={8} />

        <TextInput
          inputMode="text"
          formikContext={formikContext}
          label={uppdragsnamn.heading}
          name="uppdragsnamn"
          placeholder={uppdragsnamn.placeholder}
          maxLength={40}
        />

        <Gutter offset_xs={32} />

        <Typography variant="subtitle1">{arbetsplats.heading}</Typography>

        <Gutter offset_xs={8} />

        {isLoadingForetag && <Spinner />}

        {!isLoadingForetag && (
          <SelectInput
            disabled={isBegransadRedigering}
            selectOptions={selectOptions}
            label={arbetsplats.label}
            name="arbetsplats"
            formikContext={formikContext}
            isError={isErrorArbetsplats}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default Uppdragsinfo
