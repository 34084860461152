import * as Yup from 'yup'
import {
  IKompletteraRiskansokanFormValues,
  INyttUppdragSelectOption,
} from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanForm.types'
import { IKompletteraRiskansokanContent } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokan.types'
import { initialAnstallningRiskValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Anstallning/Anstallningrisk.schema'
import { addDays, isAfter, isBefore, isValid, subDays } from 'date-fns'
import { toTrrDateOrDefault } from '@local/Utils/helpers/formatDate'
import {
  calculateDate,
  dayAfterDate,
  dayBeforeDate,
  getEarliestOccuringDate,
  getLatestOccuringDate,
  isAfterOrEqualTo,
  octoberStart,
  startDate,
  today,
} from '@local/Utils/helpers'
import { IAnsokan } from '@local/services/API/ansokan/types'
import { AnsokanType } from '@local/Common.types'
import { getYupCurrentSchemaValues } from '@local/Utils/helpers/Forms/Forms.helpers'

export const skapaKompletteraRiskansokanValidationSchema = (
  content: IKompletteraRiskansokanContent,
  ansokan: IAnsokan
): Yup.ObjectSchema<IKompletteraRiskansokanFormValues> =>
  Yup.object().shape({
    nyttUppdrag: Yup.mixed<INyttUppdragSelectOption>()
      .nullable()
      .required(content.validering.nyttUppdragSaknas),

    overenskommelseBilagor: Yup.array().when(
      ['nyttUppdrag'],
      ([nyttUppdrag]: [INyttUppdragSelectOption]) => {
        if (nyttUppdrag?.kraverOverenskommelseBilaga) {
          return Yup.array().min(
            1,
            content.validering.overenskommelseBilagaSaknas
          )
        }
      }
    ),

    uppsagningsdatum: Yup.date()
      .typeError(content.ansokanValidering.uppsagningsdatumSaknas)
      .required(content.ansokanValidering.uppsagningsdatumSaknas)
      .test(
        'minDateMustBeAfterLatestOccuringDate',
        content.datumValidering.warningMinDate,
        (date) => {
          const datesToCompare: Date[] = [
            subDays(startDate, 1),
            toTrrDateOrDefault(
              ansokan.anstallningsuppgifter?.anstallningsdatum
            ),
          ]

          return isAfter(date, getLatestOccuringDate(datesToCompare))
        }
      )
      .test(
        'maxDateMustBeBeforeEarliestOccuringDate',
        content.datumValidering.warningMaxDate,
        (date, context) => {
          const schema =
            getYupCurrentSchemaValues<IKompletteraRiskansokanFormValues>(
              context
            )

          const datesToCompareWith = [
            calculateDate(today, { numberOfMonthsFromDate: 6 }),
          ]

          if (isValid(schema.sistaAnstallningsdatum)) {
            datesToCompareWith.push(
              toTrrDateOrDefault(addDays(schema.sistaAnstallningsdatum, 1))
            )
          }

          const earliestDate = getEarliestOccuringDate(datesToCompareWith)

          return isValid(earliestDate) ? isBefore(date, earliestDate) : true
        }
      ),

    sistaAnstallningsdatum: Yup.date()
      .typeError(content.ansokanValidering.sistaAnstallningsdatumSaknas)
      .required(content.ansokanValidering.sistaAnstallningsdatumSaknas)
      .test(
        'minDateMustBeWithinTwoYearsFromToday',
        content.ansokanValidering.sistaAnstallningsdatumMaxAmountExceeded,
        (date) =>
          isAfterOrEqualTo(
            date,
            calculateDate(today, { numberOfYearsFromDate: -2 })
          )
      )
      .test(
        'minDateMustBeAfterLatestOccuringDate',
        content.datumValidering.warningMinDate,
        (date, context) => {
          const schema = context.parent as IKompletteraRiskansokanFormValues

          const datesToCompare: Date[] = [
            startDate,
            dayAfterDate(
              toTrrDateOrDefault(
                ansokan.anstallningsuppgifter?.anstallningsdatum
              )
            ),
            schema.uppsagningsdatum,
            toTrrDateOrDefault(ansokan.loneuppgifter?.lonerevisionsdatum),
          ]

          if (
            ansokan.type === AnsokanType.omstallningTidsbegransadAnstallning
          ) {
            datesToCompare.push(octoberStart)
          }

          return isAfter(
            date,
            dayBeforeDate(getLatestOccuringDate(datesToCompare))
          )
        }
      )
      .test(
        'maxDateMustBeBeforeGivenDate',
        content.datumValidering.warningMaxDate,
        (date) => {
          const dateToCompare = calculateDate(today, {
            numberOfYearsFromDate: 3,
            numberOfDaysFromDate: 1,
          })

          return isBefore(date, dateToCompare)
        }
      ),

    uppsagningsgrad: Yup.number()
      .typeError(content.ansokanValidering.uppsagningsgradSaknas)
      .required(content.ansokanValidering.uppsagningsgradSaknas)
      .integer(content.ansokanValidering.uppsagningsgradInvalidFormat)
      .min(5, content.ansokanValidering.uppsagningsgradMinAmountRequired)
      .max(100, content.ansokanValidering.uppsagningsgradMaxAmountRequired),
  })

export const initialKompletteraRiskansokanValues: IKompletteraRiskansokanFormValues =
  {
    nyttUppdrag: '',
    overenskommelseBilagor: [],

    ...initialAnstallningRiskValues,
  }
