import { matchStringByRegex, UUIDRegex } from '@local/Utils/helpers/regexes'
import { AnsokanType, OrsakerTillUppsagning } from '@local/Common.types'
import { FormikHelpers as FormikActions, FormikValues } from 'formik'
import { isNil } from 'ramda'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { SerializedError } from '@reduxjs/toolkit'

import { trrDateUTC } from './formatDate'

type MapToOutput<I, U = I> = (v: I) => U

export const isStringAlsoNumber = (str: string) =>
  !isNil(str) && !isNaN(Number(str))

export const formatCurrencySEK: MapToOutput<number, string> = (amount) =>
  new Intl.NumberFormat('sv-se', {
    style: 'currency',
    currency: 'SEK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(amount)
    //eslint-disable-next-line
    .replace(/\,00([^\d])/g, '$1') // Edge bug where minimumFractionDigits is ignored

export const formatDecimal: MapToOutput<number, string> = (amount) =>
  new Intl.NumberFormat('sv-se', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
    .format(amount)
    //eslint-disable-next-line
    .replace(/\,00([^\d])/g, '$1') // Edge bug where minimumFractionDigits is ignored

export const formatPercentageNumber = (value: number): string => {
  const formattedNumber = value / 100

  return new Intl.NumberFormat('sv-se', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(formattedNumber)
}

export const toDigits = (numString: string) =>
  numString
    .replace(/[^0-9]/g, '')
    .split('')
    .map(Number)

/**
 * Validates personnummer with luhn (mod 10) algoritm
 * @param personnummer
 * @returns
 */
export const validatePersonnummer = (personnummer: string) => {
  const cleanPersonnummer =
    personnummer?.length > 11 ? personnummer.substring(2) : personnummer
  return cleanPersonnummer
    ? validateOrganizationNumber(cleanPersonnummer)
    : false
}

export const validateOrganizationNumber = (
  organizationNumber: string
): boolean => {
  const digits = toDigits(organizationNumber)
  const checkDigit = digits[digits.length - 1]
  const total = digits
    .slice(0, -1)
    .reverse()
    .map((current: number, index: number) =>
      index % 2 === 0 ? current * 2 : current
    )
    .map((current: number) => (current > 9 ? current - 9 : current))
    .reduce(
      (current: number, accumulator: number) => current + accumulator,
      checkDigit
    )
  return total % 10 === 0
}

/*
  Function to format identity numbers such as organization number, SSN, and so on
  Recieves a number such as "1234567890" and converts it to "123456-7890"
*/
export const formatIdentityNumber = (str: string) => {
  if (str.includes('-')) {
    return str
  }

  const firstHalf = str.substring(0, str.length - 4)
  const lastHalf = str.substring(str.length - 4)

  return `${firstHalf}-${lastHalf}`
}

export const setFieldValue =
  (
    _setFieldValue: FormikActions<FormikValues>['setFieldValue'],
    fieldName: string,
    _setFieldTouched?: FormikActions<FormikValues>['setFieldTouched']
  ) =>
  (value: unknown): void => {
    const wrappedValue =
      value instanceof Date ? trrDateUTC(value).valueOf() : value
    _setFieldValue(fieldName, wrappedValue).catch((err) => {
      console.log(err)
    })
    if (_setFieldTouched) {
      _setFieldTouched(fieldName, true, false).catch((err) => {
        console.log(err)
      })
    }
  }

export const countDecimals = (value: number): number => {
  if (!value || Math.floor(value) === value) {
    return 0
  }
  return value.toString().split('.')[1].length || 0
}

export const appendComma = (value: string | null) => (value ? value + ', ' : '')

export const getAnsokanId = () =>
  matchStringByRegex(window.location.pathname, UUIDRegex)

export const getAnsokantypByOrsakerTillUppsagning = (
  orsak: OrsakerTillUppsagning
): AnsokanType => {
  switch (orsak) {
    case OrsakerTillUppsagning.Risk:
      return AnsokanType.omstallningTillsvidareanstallningRisk
    case OrsakerTillUppsagning.TidsbegransadAnstallning:
      return AnsokanType.omstallningTidsbegransadAnstallning
    default:
      return AnsokanType.omstallningTillsvidareanstallning
  }
}

export const removeHtmlTags = (str: string): string =>
  str.toString().replace(/(<([^>]+)>)/gi, '')

export const removeUnicodeGeneralPunctuationChars = (str: string) =>
  str.replace(/[\u2000-\u206F]/g, '')

export const isFetchBaseQueryError = (
  error: FetchBaseQueryError | SerializedError,
  statusCode?: number
): error is FetchBaseQueryError => {
  if (statusCode) {
    return (
      typeof error === 'object' &&
      !isNil(error) &&
      'status' in error &&
      error.status === statusCode
    )
  }

  return typeof error === 'object' && !isNil(error) && 'status' in error
}

export const replaceEpiVariables = (
  text: string,
  variables: { [key: string]: string } = {}
) => {
  let result = text
  Object.keys(variables).forEach((key) => {
    const regex = new RegExp(`{{${key}}}`, 'g')
    result = result.replace(regex, variables[key])
  })
  return result
}

export * from './Datetime.helpers'
export * from './regexes'
