import { baseApi } from '@local/services/API/baseApi'
import {
  IDeleteFilAnsokanModel,
  IFilAnsokanResponse,
  IFilAnsokanRow,
  IGetFilAnsokanDraftRequestModel,
  IPostFilAnsokanModel,
  IUploadFilAnsokanModel,
  IPutFilAnsokanModel,
  IDeleteFilAnsokanDraftRowPayloadModel,
} from '@local/services/API/filansokan/types'

export const filAnsokanApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: (builder) => ({
    getFilAnsokan: builder.query<IFilAnsokanResponse, string>({
      query: (uppdragsId) =>
        `ansokan/filansokan/uppdrag/${uppdragsId}/columnheaders`,
      providesTags: ['filansokan'],
    }),

    getFilAnsokanDraft: builder.query<
      IFilAnsokanRow[],
      IGetFilAnsokanDraftRequestModel
    >({
      query: ({ fileId, uppdragsId }) =>
        `ansokan/filansokan/uppdrag/${uppdragsId}/file/${fileId}`,
      providesTags: [{ type: 'filansokan', id: 'DRAFT' }],
      keepUnusedDataFor: 0,
    }),

    uploadFilAnsokan: builder.mutation<Response, IUploadFilAnsokanModel>({
      query: ({ formData, uppdragsId }) => ({
        url: `ansokan/filansokan/uppdrag/${uppdragsId}/file`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['filansokan'],
    }),
    deleteFilAnsokan: builder.mutation<Response, IDeleteFilAnsokanModel>({
      query: ({ uppdragsId, fileId }) => ({
        url: `ansokan/filansokan/uppdrag/${uppdragsId}/file/${fileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['filansokan'],
    }),
    postFilAnsokan: builder.mutation<Response, IPostFilAnsokanModel>({
      query: ({ uppdragsId, fileId, villkorsText }) => ({
        url: `ansokan/filansokan/uppdrag/${uppdragsId}/file/${fileId}/skickain`,
        method: 'POST',
        body: { villkorsText },
      }),
      invalidatesTags: [
        'filansokan',
        { type: 'ansokan', id: 'LIST' },
        { type: 'ansokan', id: 'GROUPED_LIST' },
      ],
    }),
    putFilAnsokan: builder.mutation<Response, IPutFilAnsokanModel>({
      query: ({ uppdragsId, fileId, rowId, rowData }) => ({
        url: `ansokan/filansokan/uppdrag/${uppdragsId}/file/${fileId}/row/${rowId}`,
        method: 'PUT',
        body: rowData,
      }),
      invalidatesTags: [{ type: 'filansokan', id: 'DRAFT' }],
    }),
    deleteFilansokanDraftRow: builder.mutation<
      Response,
      IDeleteFilAnsokanDraftRowPayloadModel
    >({
      query: ({ uppdragsId, fileId, rowId }) => ({
        url: `ansokan/filansokan/uppdrag/${uppdragsId}/file/${fileId}/row/${rowId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'filansokan', id: 'DRAFT' }],
    }),
  }),
})

export const {
  useGetFilAnsokanQuery,
  useGetFilAnsokanDraftQuery,
  useUploadFilAnsokanMutation,
  useDeleteFilAnsokanMutation,
  usePostFilAnsokanMutation,
  useDeleteFilansokanDraftRowMutation,
  usePutFilAnsokanMutation,
} = filAnsokanApi
