import * as Yup from 'yup'
import {
  FackligKontaktOrsakerTillUppsagning,
  ISelectOption,
  OrsakerTillUppsagning,
} from '@local/Common.types'
import {
  IFackligKontakt,
  IUppdragFormWrapperContent,
} from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'

import { uppdragFackligaKontakterValidationSchema } from './UppdragFormFackligaKontakter.schema'

export const uppdragValidationSchema = (
  content: IUppdragFormWrapperContent
): Yup.ObjectSchema<IUppdragFormValues> =>
  Yup.object().shape({
    uppdragsnamn: Yup.string()
      .nullable()
      .required(content.validering.uppdragsnamnSaknas),
    arbetsplats: Yup.mixed<ISelectOption>()
      .nullable()
      .required(content.validering.arbetsplatsSaknas),
    orsakTillUppsagning: Yup.mixed<OrsakerTillUppsagning>()
      .nullable()
      .required(content.validering.orsakTillUppsagningSaknas),

    fackligaKontakter: Yup.array()
      .when(
        ['orsakTillUppsagning', 'fackligRepSaknas'],
        (
          [orsak, fackligRepSaknas]: [OrsakerTillUppsagning, boolean],
          schema
        ) => {
          if (
            Object.values<string>(FackligKontaktOrsakerTillUppsagning).includes(
              orsak
            ) &&
            !fackligRepSaknas
          ) {
            return schema
              .of<IFackligKontakt>(
                uppdragFackligaKontakterValidationSchema(content)
              )
              .min(1, content.validering.fackligRepSaknas)
          }
        }
      )
      .when(
        ['orsakTillUppsagning'],
        ([orsakTillUppsagning]: [OrsakerTillUppsagning], schema) => {
          if (orsakTillUppsagning === OrsakerTillUppsagning.Arbetsbrist) {
            return schema
              .of<IFackligKontakt>(
                uppdragFackligaKontakterValidationSchema(content)
              )
              .min(1, content.validering.fackligRepSaknas)
          }
        }
      ),

    fackligRepSaknas: Yup.boolean(),

    admins: Yup.array()
      .of<ISelectOption>(Yup.mixed<ISelectOption>().nullable())
      .nullable(),
  })

export const initialUppdragValues: IUppdragFormValues = {
  uppdragsnamn: '',
  arbetsplats: '',
  orsakTillUppsagning: null,
  fackligaKontakter: [],
  fackligRepSaknas: false,
  admins: [],
}
