import { IKeyValue, FilAnsokanKeys } from '@local/Common.types'

export interface IColumnHeaders {
  key: string
  filansokanKey: FilAnsokanKeys
  value: string
}

export interface FileError {
  reason: keyof typeof FilePickerError
  name: string
}

export enum FilePickerError {
  MIN_AMOUNT_OF_FILES_NOT_REACHED = 'minAmountOfFilesNotReached',
  MAX_AMOUNT_OF_FILES_EXCEEDED = 'maxAmountOfFilesExceeded',
  FILE_SIZE_TOO_LARGE = 'fileSizeTooLarge',
  FILE_SIZE_TOO_SMALL = 'fileSizeTooSmall',
  FILE_TYPE_NOT_ACCEPTED = 'fileTypeNotAccepted',
}
export interface IFilePickerErrorContent extends IKeyValue {
  minAmountOfFilesNotReached: string
  maxAmountOfFilesExceeded: string
  fileSizeTooLarge: string
  fileSizeTooSmall: string
  fileTypeNotAccepted: string
}
