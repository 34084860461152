import { JSX, useCallback, useEffect, useState } from 'react'
import { usePickEpiContent } from '@local/Utils/hooks'
import Gutter from '@local/Components/Gutter'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { decode } from 'html-entities'
import { removeHtmlTags } from '@local/Utils/helpers'
import { useHistory } from 'react-router-dom'
import Alert from '@local/Components/Alert'
import HTMLMapper from '@local/Components/HTMLMapper'
import {
  useGetFilAnsokanQuery,
  usePostFilAnsokanMutation,
} from '@local/services/API/filansokan/filAnsokanApi'
import { ISkapaAnsokningarContent } from '@local/Views/SkapaAnsokningar/types/types'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import Highlightbox from '@local/Components/Highlightbox'

import { ISkickaInModal } from './SkickaInModal.types'

const SkickaInModal = ({
  modalOpen,
  closeModal,
  uppdragId,
}: ISkickaInModal): JSX.Element => {
  const shouldTrack = useIsFeatureEnabled('gtm.enabled.kundansokan')
  const trackButtonClick = useTrackCustomClick()

  const {
    arbetsgivaransokanLinks: { uppdragUrl },
    granskaSkickaIn: { skickaInJuridiskText, skickaInModal },
  } = usePickEpiContent<ISkapaAnsokningarContent>()
  const history = useHistory()
  const [checked, setChecked] = useState(false)
  const [errorMessage, setErrorMessage] = useState(skickaInModal.felmeddelande)
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false)

  const [skickaInFil, { isLoading }] = usePostFilAnsokanMutation()
  const { data: uploadedFile } = useGetFilAnsokanQuery(uppdragId)

  const toggleChecked = () => {
    setChecked(!checked)
  }
  useEffect(() => {
    if (checked) {
      setShouldShowErrorMessage(false)
    }
  }, [checked])

  const handleOnConfirmError = useCallback(() => {
    setErrorMessage(skickaInModal.felmeddelande)
    setShouldShowErrorMessage(true)
  }, [setShouldShowErrorMessage, setErrorMessage, skickaInModal.felmeddelande])

  const handleSkickaInFilAnsokan = useCallback(() => {
    if (shouldTrack) {
      trackButtonClick('Modal: Skicka in ansökningar', {
        label: 'Skicka in',
      })
    }
    skickaInFil({
      uppdragsId: uppdragId,
      fileId: uploadedFile.fileId,
      villkorsText: decode(removeHtmlTags(skickaInJuridiskText.mainBody)),
    })
      .unwrap()
      .then(() => {
        setTimeout(() => {
          history.push(`${uppdragUrl}${uppdragId}/`)
          closeModal()
        }, 50)
      })
      .catch((err) => {
        setErrorMessage(skickaInModal.felmeddelandeRequest)
        setShouldShowErrorMessage(true)
        console.log(err)
      })
  }, [
    shouldTrack,
    skickaInFil,
    uppdragId,
    uploadedFile,
    skickaInJuridiskText,
    trackButtonClick,
    history,
    uppdragUrl,
    closeModal,
    skickaInModal,
  ])

  const handleOnClose = () => {
    if (shouldTrack) {
      trackButtonClick('Modal: Skicka in ansökningar', {
        label: 'Avbryt',
      })
    }
    closeModal()
  }

  return (
    <Dialog
      onClose={handleOnClose}
      open={modalOpen}
      closeAfterTransition={false}
    >
      <DialogTitle>{skickaInModal.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body1"
          sx={(theme) => ({
            color: theme.palette.text.primary,
          })}
        >
          {skickaInModal.preamble}
        </DialogContentText>

        <Gutter offset_xs={24} />

        <Highlightbox padding_md={24}>
          <Typography variant="h6">
            {skickaInModal.juridiskTextTitle}
          </Typography>

          <Gutter offset_xs={12} />

          <HTMLMapper body={skickaInJuridiskText.mainBody} />
        </Highlightbox>

        <Gutter offset_xs={24} />

        <DialogContentText variant="caption">
          {skickaInModal.kollektivavtalDescription}
        </DialogContentText>

        <Gutter offset_xs={24} />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={checked}
              aria-checked={checked}
              onChange={toggleChecked}
            />
          }
          label={skickaInModal.checkboxLabel}
        />

        {shouldShowErrorMessage && (
          <>
            <Gutter offset_xs={24} />
            <Alert severity="error">{errorMessage}</Alert>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button disabled={isLoading} variant="text" onClick={handleOnClose}>
          {skickaInModal.cancelText}
        </Button>
        <Button
          disabled={isLoading}
          variant="text"
          onClick={checked ? handleSkickaInFilAnsokan : handleOnConfirmError}
        >
          {skickaInModal.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SkickaInModal
