import { AnsokanState } from '@local/Common.types'
import { usePickEpiContent } from '@local/Utils/hooks'
import { useGetUppdragIsRisk } from '@local/Utils/hooks/useGetUppdragIsRisk'
import { selectAnsokanList } from '@local/Views/Uppdrag/AnsokanList/ansokanListSlice'
import { IUppdragContent } from '@local/Views/Uppdrag/Uppdrag.types'
import { useSelector } from 'react-redux'

interface IOverlayContent {
  heading: string
  body: string
}

const useGetOverlayContent = (): IOverlayContent => {
  const isRisk = useGetUppdragIsRisk()
  const { activeTab } = useSelector(selectAnsokanList)

  const {
    ingaRaderOverlay: {
      ejInskickadeHeading,
      ejInskickadeBody,
      inskickadeHeading,
      inskickadeBody,
      bedomdaHeading,
      bedomdaBody,
      ejInskickadeHeadingRisk,
      ejInskickadeBodyRisk,
      inskickadeHeadingRisk,
      inskickadeBodyRisk,
    },
  } = usePickEpiContent<IUppdragContent>()

  switch (activeTab) {
    case AnsokanState.Utkast:
      return {
        heading: isRisk ? ejInskickadeHeadingRisk : ejInskickadeHeading,
        body: isRisk ? ejInskickadeBodyRisk : ejInskickadeBody,
      }
    case AnsokanState.Inskickad:
      return {
        heading: inskickadeHeading,
        body: inskickadeBody,
      }
    case AnsokanState.KompletteraRisk:
      return {
        heading: inskickadeHeadingRisk,
        body: inskickadeBodyRisk,
      }

    case AnsokanState.Bedomd:
      return {
        heading: bedomdaHeading,
        body: bedomdaBody,
      }

    default:
      return {
        heading: null,
        body: null,
      }
  }
}

export default useGetOverlayContent
