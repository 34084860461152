import { validFileExtensions } from '@local/Components/FileUpload/fileUpload.helpers'
import { IUniqueFile } from '@local/services/API/bilagor/types'
import { FormikErrors, FormikHelpers, FormikValues } from 'formik'

export interface IFileUpload {
  isLoadingUpload: boolean
  content: IFileUploadContent
  files: IUniqueFile[]
  customClass?: string
  errorMessage?: string | FormikErrors<FormikValues>
  multiple?: boolean
  inputName?: string
  setFieldValue?: FormikHelpers<FormikValues>['setFieldValue']
  filePickedHandler?: (file: IUniqueFile, fileStream: File) => Promise<unknown>
  fileDeletedHandler?: (file: IUniqueFile) => Promise<unknown>
}

export interface IFileUploadContent {
  uploadButtonText: string
  heading?: string
  fileSizeLimitExceeded: string
  fileExtensionError: string
  fileUploadError: string
  fileUploadCannotReadFileError: string
  fileDeletedError: string
  description: string
  tooltip: string
}

export interface FileValidation {
  file: File
  validSize: boolean
  validFileExtension: boolean
}

export type ValidFileExtentions = (typeof validFileExtensions)[number]

export class FileListError extends Error {
  public filesValidation: FileValidation[]
  public allowedFileExtentions: readonly ValidFileExtentions[]
  public fileSizeLimit: number

  public constructor(
    message: string,
    filesValidation: FileValidation[],
    allowedFileExtentions: readonly ValidFileExtentions[],
    fileSizeLimit: number
  ) {
    super(message)
    this.filesValidation = filesValidation
    this.allowedFileExtentions = allowedFileExtentions
    this.fileSizeLimit = fileSizeLimit
  }
}
