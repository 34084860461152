import { useEffect, useState } from 'react'
import { useFilePicker } from 'use-file-picker'
import {
  FileTypeValidator,
  FileSizeValidator,
} from 'use-file-picker/validators'
import { Button, Grid2 as Grid } from '@mui/material'
import Spinner from '@local/Components/Spinner'
import FileNamePreview from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/LaddaUppFil/FileUploader/FileNamePreview'
import { usePickEpiContent } from '@local/Utils/hooks'
import {
  ISkapaAnsokningarContent,
  ISkapaAnsokningarFormValues,
} from '@local/Views/SkapaAnsokningar/types/types'
import {
  useGetFilAnsokanQuery,
  useUploadFilAnsokanMutation,
  useDeleteFilAnsokanMutation,
} from '@local/services/API/filansokan/filAnsokanApi'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/helpers'
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded'
import Toaster from '@local/Components/Toaster'
import { useFormikContext } from 'formik'
import { isNil } from 'ramda'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import Gutter from '@local/Components/Gutter'
import {
  FileError,
  FilePickerError,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/LaddaUppFil/types/types'
import getConfig from '@local/Utils/getConfig'
import { useAppDispatch } from '@local/Store/configureStore'
import { setExcelShouldBePreset } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/laggTillMedarbetareSlice'

const FileUploader = () => {
  const dispatch = useAppDispatch()
  const {
    laggTillMedarbetare: { laddaUppFil, excelmall, filePickerError },
    nagotGickFel,
  } = usePickEpiContent<ISkapaAnsokningarContent>()
  const [toasterOpen, setToasterOpen] = useState(false)
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  )

  const { MEDIA_URL } = getConfig()
  const downloadExcelMallURL = `${MEDIA_URL}${excelmall.url}`

  const formikContext = useFormikContext<ISkapaAnsokningarFormValues>()
  const { touched, setFieldValue, errors: formikErrors } = formikContext
  const isFormikError = touched.file && !isNil(formikErrors.file)

  const uppdragsId = matchStringByRegex(location.pathname, UUIDRegex)

  const {
    data: uploadedFile,
    isLoading: isLoadingUploadedFile,
    error: errorLoading,
    isFetching: isFetchingUploadedFile,
  } = useGetFilAnsokanQuery(uppdragsId)

  const [uploadFile, { isLoading: isUploading, error: errorUploading }] =
    useUploadFilAnsokanMutation()

  const [, { error: errorDeleting, isLoading: isLoadingDelete }] =
    useDeleteFilAnsokanMutation({
      fixedCacheKey: 'shared-filAnsokan-delete',
    })

  const isLoading =
    isLoadingUploadedFile ||
    isUploading ||
    isFetchingUploadedFile ||
    isLoadingDelete
  const shouldShowFile = !isLoading && uploadedFile?.filnamn

  const { openFilePicker } = useFilePicker({
    multiple: false,
    readAs: 'DataURL',
    accept: ['.xlsx'],
    validators: [
      new FileSizeValidator({ maxFileSize: 10000000 }),
      new FileTypeValidator(['xlsx']),
    ],
    onFilesSuccessfullySelected: async (files) => {
      const file = files.filesContent[0]
      const response = await fetch(file.content)
      const blob: Blob = await response.blob()
      const formData = new FormData()
      formData.append('file', blob, file.name)

      setCurrentErrorMessage(null)
      dispatch(setExcelShouldBePreset(true))
      await uploadFile({ formData, uppdragsId })
    },
    onFilesRejected: (error) => {
      error.errors.map((error: FileError) => {
        if (error.reason in FilePickerError) {
          const errorMessage = filePickerError[FilePickerError[error.reason]]
          setCurrentErrorMessage(errorMessage)
        } else {
          setCurrentErrorMessage(nagotGickFel.heading)
        }
      })
    },
  })

  useEffect(() => {
    if (isFormikError) {
      setCurrentErrorMessage(formikErrors.file as string)
    }
  }, [formikErrors, isFormikError, setCurrentErrorMessage])

  useEffect(() => {
    if (uploadedFile.filnamn) {
      setFieldValue('file', {
        id: uploadedFile.fileId,
        name: uploadedFile.filnamn,
        uploadedAt: uploadedFile.uploadedAt,
      })?.catch((err) => {
        console.log(err)
      })
    } else {
      setFieldValue('file', null)?.catch((err) => {
        console.log(err)
      })
    }
  }, [uploadedFile, setFieldValue])

  useEffect(() => {
    if (errorUploading || errorDeleting || errorLoading) {
      setToasterOpen(true)
    } else {
      setToasterOpen(false)
    }
  }, [errorDeleting, errorLoading, errorUploading])

  if (isLoading) {
    return <Spinner centered />
  }

  return (
    <>
      <Grid
        container
        justifyContent={'flex-start'}
        alignContent={'center'}
        spacing={2}
      >
        {!shouldShowFile && (
          <>
            <Grid>
              <Button
                onClick={() => {
                  openFilePicker()
                }}
              >
                {laddaUppFil.uploadButtonText}
              </Button>
            </Grid>
            <Grid>
              <Button
                variant="text"
                color="primary"
                startIcon={<DownloadForOfflineRoundedIcon />}
                href={downloadExcelMallURL}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                {excelmall.heading}
              </Button>
            </Grid>
          </>
        )}
        {shouldShowFile && (
          <Grid size={{ xs: 12 }}>
            <FileNamePreview />
          </Grid>
        )}
      </Grid>
      {currentErrorMessage && (
        <>
          <Gutter offset_xs={16} />
          <FormErrorMessage>{currentErrorMessage}</FormErrorMessage>
        </>
      )}

      <Toaster
        message={laddaUppFil.nagotGickFel}
        open={toasterOpen}
        setOpen={setToasterOpen}
      />
    </>
  )
}

export default FileUploader
