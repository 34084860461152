import { JSX, useEffect } from 'react'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import { useFormikContext } from 'formik'
import { generateErrorMessage } from '@local/Utils/helpers/Forms/Forms.helpers'
import CommonOverenskommelseBilaga from '@local/Components/OverenskommelseBilaga'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import { usePickEpiContent } from '@local/Utils/hooks'
import HTMLMapper from '@local/Components/HTMLMapper'
import { useAppDispatch } from '@local/Store/configureStore'
import { setOverenskommelseBilagor } from '@local/Views/SkapaAnsokan/skapaAnsokanSlice'
import useGetAllOverenskommelseBilaga from '@local/Components/OverenskommelseBilaga/useGetAllOverenskommelseBilaga'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import Spinner from '@local/Components/Spinner'
import Alert from '@local/Components/Alert'
import { AnsokanKeys } from '@local/Common.types'

const OverenskommelseBilaga = (): JSX.Element => {
  const { errors, touched, values, setFieldValue } =
    useFormikContext<ISkapaAnsokanFormValues>()

  const {
    medarbetare: {
      laddaUppBilaga,
      overenskommelseBilaga,
      tooltipOverenskommelseBilaga,
    },
    nagotGickFel,
  } = usePickEpiContent<ISkapaAnsokanContent>()
  const dispatch = useAppDispatch()

  const { data: bilagor, isLoading, isError } = useGetAllOverenskommelseBilaga()

  useEffect(() => {
    if (bilagor) {
      dispatch(setOverenskommelseBilagor(bilagor))
    }
  }, [dispatch, bilagor])

  if (isLoading) {
    return <Spinner centered />
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <>
      <Tooltip
        variant="subtitle1"
        heading={overenskommelseBilaga.heading}
        tooltipContent={
          <HTMLMapper body={tooltipOverenskommelseBilaga.mainBody} />
        }
      />

      <CommonOverenskommelseBilaga
        content={laddaUppBilaga}
        formikFieldName={AnsokanKeys.OverenskommelseBilagor}
        files={values.overenskommelseBilagor}
        errorMessage={generateErrorMessage({
          touched: touched?.overenskommelseBilagor !== undefined,
          errorMsg: errors?.overenskommelseBilagor as string,
        })}
        setFieldValue={setFieldValue}
        nagotGickFel={nagotGickFel}
      />
    </>
  )
}

export default OverenskommelseBilaga
