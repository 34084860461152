import { JSX, useEffect, useState } from 'react'
import { Chip, Grid2 as Grid, Skeleton, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material/styles'
import AssignmentIcon from '@mui/icons-material/Assignment'
import ChipEllipsis from '@local/Components/ChipEllipsis'
import { usePickEpiContent } from '@local/Utils/hooks'
import { IUppdragContent } from '@local/Views/Uppdrag/Uppdrag.types'
import Toaster from '@local/Components/Toaster'
import DetaljerModal from '@local/Views/Uppdrag/Header/DetaljerModal'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'
import PopupMenu from '@local/Components/PopupMenu'
import TaBortUppdragModal from '@local/Views/Uppdrag/Header/TaBortUppdragModal'

const SecondaryText = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 400,
  marginRight: theme.spacing(1),
}))

const Header = (): JSX.Element => {
  const { data: uppdrag, isLoading, isError } = useGetUppdrag()
  const [toasterOpen, setToasterOpen] = useState(false)
  const [showDetaljerModal, setShowDetaljerModal] = useState(false)
  const [showTaBortUppdragModal, setShowTaBortUppdragModal] = useState(false)

  useEffect(() => {
    if (isError) {
      setToasterOpen(true)
    } else {
      setToasterOpen(false)
    }
  }, [isError])

  const shouldShowSkeleton = isLoading || isError || !uppdrag?.namn
  const shouldShowSkeletonId = !uppdrag?.nummer

  const { orsakTillUppsagning, arbetsgivaransokanLinks, header, nagotGickFel } =
    usePickEpiContent<IUppdragContent>()

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent={'flex-start'}
        alignItems={'center'}
        gap={1}
      >
        <Grid>
          <Typography variant="h4">
            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="center"
            >
              {!shouldShowSkeletonId && (
                <SecondaryText>{`#${uppdrag?.nummer}`}</SecondaryText>
              )}
              {shouldShowSkeletonId && (
                <Skeleton
                  variant="text"
                  width={95}
                  height={40}
                  sx={{ marginRight: 1 }}
                />
              )}
              <>
                {shouldShowSkeleton && (
                  <Skeleton variant="text" width={280} height={40} />
                )}
                {!shouldShowSkeleton && (
                  <span>
                    {uppdrag?.namn}
                    <PopupMenu
                      menuItems={[
                        {
                          label: header.detaljerButtonLabel,
                          icon: <AssignmentIcon />,
                          onClick: () => {
                            setShowDetaljerModal(true)
                          },
                        },
                        {
                          label: header.redigeraButtonLabel,
                          icon: <EditIcon />,
                          linkTo: `${arbetsgivaransokanLinks.redigeraUppdragUrl}${uppdrag?.id}/`,
                        },
                        {
                          label: header.deleteButtonLabel,
                          icon: <DeleteIcon />,
                          onClick: () => {
                            setShowTaBortUppdragModal(true)
                          },
                        },
                      ]}
                    />
                  </span>
                )}
              </>
            </Grid>
          </Typography>
        </Grid>

        <Grid>
          <ChipEllipsis>
            <Chip
              label={
                !shouldShowSkeleton ? (
                  orsakTillUppsagning[uppdrag.orsakTillUppsagning]
                ) : (
                  <Skeleton variant="text" width={100} />
                )
              }
              variant="outlined"
            />
          </ChipEllipsis>
        </Grid>
      </Grid>
      <Toaster
        message={nagotGickFel.heading}
        open={toasterOpen}
        setOpen={setToasterOpen}
      />
      <DetaljerModal
        modalOpen={showDetaljerModal}
        closeModal={() => {
          setShowDetaljerModal(false)
        }}
      />
      <TaBortUppdragModal
        modalOpen={showTaBortUppdragModal}
        closeModal={() => {
          setShowTaBortUppdragModal(false)
        }}
      />
    </>
  )
}

export default Header
