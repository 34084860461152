import { JSX } from 'react'
import { Grid2 as Grid, Skeleton, Typography } from '@mui/material'
import Gutter from '@local/Components/Gutter'

const KeyValuePair = ({
  title,
  value,
  isLoading = false,
}: {
  title: string
  value: string
  isLoading?: boolean
}): JSX.Element => (
  <Grid size={{ xs: 12, sm: 6, md: 3 }}>
    <Typography variant="subtitle2" sx={{ opacity: 0.7 }}>
      {title}
    </Typography>

    <Gutter offset_xs={8} />

    {isLoading && <Skeleton variant="rounded" />}

    {!isLoading && <Typography variant="body2">{value}</Typography>}
  </Grid>
)

export default KeyValuePair
