import { getDropdownSelectOptionValue } from '@local/Utils/helpers/Forms/Forms.helpers'
import { IKompletteraRiskansokanFormValues } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanForm.types'
import { trrDateUTC } from '@local/Utils/helpers/formatDate'
import { IKompletteraRiskApiModel } from '@local/services/API/kompletteraRisk/types'

/**
 * Patches komplettera riskansokan form values for sending to server
 */
export const getMappedKompletteraRiskansokanFormValues = (
  values: IKompletteraRiskansokanFormValues
): IKompletteraRiskApiModel => ({
  sistaAnstallningsdatum: trrDateUTC(values.sistaAnstallningsdatum),
  uppsagningsdatum: trrDateUTC(values.uppsagningsdatum),
  uppsagningsgrad: values.uppsagningsgrad,
  nyttUppdragId: getDropdownSelectOptionValue(values.nyttUppdrag) as string,
})
