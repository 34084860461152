import {
  ILaggTillMedarbetareFormValues,
  LaggTillMedarbetareFormTypes,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/types/types'
import { isNil } from 'ramda'
import { IFilAnsokanResponse } from '@local/services/API/filansokan/types'

export const mapFilAnsokanToLaggTillMedarbetareFormValues = (
  filansokan: IFilAnsokanResponse,
  excelShouldBePreset: boolean
): ILaggTillMedarbetareFormValues => ({
  laggTillMedarbetareChoice: excelShouldBePreset
    ? LaggTillMedarbetareFormTypes.LaggTillMedarbetareExcel
    : null,
  file: isNil(filansokan.fileId)
    ? null
    : {
        id: filansokan.fileId,
        name: filansokan.filnamn,
        uploadedAt: filansokan.uploadedAt,
      },
})
