import * as Yup from 'yup'
import { email, phone } from '@local/Utils/helpers/regexes'
import {
  IFackligKontakt,
  IUppdragFormWrapperContent,
} from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import { ISelectOptionFormikWrapper } from '@local/Common.types'

export const uppdragFackligaKontakterValidationSchema = (
  content: IUppdragFormWrapperContent
): Yup.ObjectSchema<IFackligKontakt> =>
  Yup.object().shape({
    key: Yup.string().nullable(),
    index: Yup.number().nullable(),
    fornamn: Yup.string().nullable().required(content.validering.fornamnSaknas),
    efternamn: Yup.string()
      .nullable()
      .required(content.validering.efternamnSaknas),
    epostadress: Yup.string()
      .nullable()
      .required(content.validering.epostSaknas)
      .matches(email, {
        message: content.validering.epostOgiltig,
      }),
    telefon: Yup.string()
      .nullable()
      .required(content.validering.telefonSaknas)
      .matches(phone, { message: content.validering.telefonOgiltig }),
    fackforbund: Yup.mixed<ISelectOptionFormikWrapper>()
      .nullable()
      .required(content.validering.fackforbundSaknas),
  })
