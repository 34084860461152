import {
  IArbetsgivaransokanLinks,
  IEditorialBlock,
  IKeyValue,
  INagotGickFelContent,
  IVarningLamnaSidan,
} from '@local/Common.types'
import {
  ILaggTillMedarbetareContent,
  ILaggTillMedarbetareFormValues,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/types/types'
import {
  ISammanfattningContent,
  ISammanfattningFormValues,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/types/types'
import {
  IFilAnsokanResponse,
  IFilAnsokanRow,
} from '@local/services/API/filansokan/types'
import { ObjectSchema } from 'yup'

export interface ISkapaAnsokningarFormProps {
  filansokan: IFilAnsokanResponse
  filansokanRows: IFilAnsokanRow[]
  excelShouldBePreset: boolean
  skapaAnsokningarSchema: ObjectSchema<unknown>
}

export interface ISkapaAnsokningarFormValues
  extends IKeyValue,
    ILaggTillMedarbetareFormValues,
    ISammanfattningFormValues {}

export interface ISkapaAnsokningarContent {
  steps: ISkapaAnsokningarStepsContent
  sidfot: ISidfotContent
  fullscreenHeader: IFullscreenHeaderContent
  sammanfattning: ISammanfattningContent
  laggTillMedarbetare: ILaggTillMedarbetareContent
  arbetsgivaransokanLinks: IArbetsgivaransokanLinks
  nagotGickFel: INagotGickFelContent
  granskaSkickaIn: IGranskaSkickaInContent
  varningLamnaSidan: IVarningLamnaSidan
  avbrytFilansokanModal: IAvbrytFilansokanModalContent
}

export interface IAvbrytFilansokanModalContent {
  heading: string
  description: string
  confirmText: string
  cancelText: string
}

export interface ISkapaAnsokningarStepsContent extends IKeyValue {
  heading: string
  laggTillMedarbetare: string
  sammanfattning: string
  granskaSkickaIn: string
  finalStep: string
}

export interface ISidfotContent {
  avsluta: string
  felmeddelande: string
  nastaSteg: string
  slutfor: string
  sparaUppgifter: string
  tillbaka: string
}

export interface IFullscreenHeaderContent {
  avbryt: string
  hjalp: string
}

export interface IGranskaSkickaInIngressContent {
  heading: string
  preamble: string
}

export interface IGranskaSkickaInContent {
  ingress: IGranskaSkickaInIngressContent
  uppdragsdetaljer: IGranskaSkickaInUppdragsdetaljerContent
  skickaInJuridiskText: IEditorialBlock
  skickaInModal: ISkickaInModalContent
}
export interface ISkickaInModalContent {
  heading: string
  confirmText: string
  cancelText: string
  preamble: string
  juridiskTextTitle: string
  felmeddelande: string
  felmeddelandeRequest: string
  checkboxLabel: string
  kollektivavtalDescription: string
}

export interface IGranskaSkickaInUppdragsdetaljerContent {
  uppsagningsorsak: string
  fackligaKontakter: string
  arbetsplats: string
  administratorer: string
  antalTillagdaMedarbetare: string
  medarbetareUnit: string
}

export enum SkapaAnsokningarStep {
  LaggTillMedarbetare = 0,
  Sammanfattning = 1,
  GranskaSkickaIn = 2,
}

export enum SkapaAnsokningarStepFormikId {
  LaggTillMedarbetare = 'laggTillMedarbetare',
  Sammanfattning = 'sammanfattning',
  GranskaSkickaIn = 'granskaSkickaIn',
}
